import { useState } from "react";
import { Button, Segment, SegmentGroup } from "semantic-ui-react";

import { HighLightType, ISearchResultList } from "./types";
import { IFindResult } from "../../../../functions/src/http/findandreplace/find";
import SearchResultItem from "./SearchResultItem";
import EmptyResultSet from "./EmptyResultSet";
import { extractUpdateObject, replaceInObject } from "./Shared";
import { toastr } from "react-redux-toastr";
import firebase from 'firebase/app'
import 'firebase/firestore'
import { connect } from "react-redux";

const SearchResultList = ({
  searchTerm,
  results,
  replaceTerm,
  fields,
  currentUser
}: ISearchResultList) => {
  const [bulkApplyChecked, setBulkApplyChecked] = useState<string[]>(
    []
  );

  const [replaceAppliedList, setAppliedList]  = useState<string[]>([])

  const onBulkApplyChange = (
    id: string,
  ) => {
    if (!bulkApplyChecked.includes(id)) {
      setBulkApplyChecked(prev => [...prev, id]);
    } else {
      setBulkApplyChecked(prev => prev.filter(x => id !== x));
    }
  };

  const onReplaceAll = async () => {
    

    await results.forEach(async (item) => {
      const nodeUpdate = JSON.parse(JSON.stringify(item.nodeContent));

      replaceInObject(
        nodeUpdate,
        searchTerm,
        replaceTerm,
        fields,
        HighLightType.None
      );
  
      const limitedUpdateNode = extractUpdateObject(nodeUpdate,replaceTerm,fields, currentUser || "")
      const nodeReference = firebase.firestore().collection(item.nodePath).doc(item.nodeId);
      await nodeReference.update(limitedUpdateNode)
     
    });
    onReplaceBulkApplied(results.map(x=> x.nodeId))
  };

  const onReplaceBulkApplied = (ids: string[]) => {
    setAppliedList((prev)=> [...prev,...ids])
    toastr.success("Success","Replace applied")
  }
  const onReplaceApplied = (id: string)=>{
    setAppliedList((prev)=> [...prev,id])
    toastr.success("Success","Replace applied")
  }

  const onReplaceSelected = async () => {
    const replacesToApply = results.filter((x) =>
        bulkApplyChecked.includes(x.nodeId)
    );

    await replacesToApply.forEach(async (item) => {
      const nodeUpdate = JSON.parse(JSON.stringify(item.nodeContent));

      replaceInObject(
        nodeUpdate,
        searchTerm,
        replaceTerm,
        fields,
        HighLightType.None
      );
      const limitedUpdateNode = extractUpdateObject(nodeUpdate,replaceTerm,fields, currentUser || "")

      const nodeReference = firebase.firestore().collection(item.nodePath).doc(item.nodeId);
      await nodeReference.update(limitedUpdateNode)
    });

    onReplaceBulkApplied(bulkApplyChecked)

  };
  return (
    <>
      <Segment basic>
        <h3 style={{ textAlign: "center" }}>Results</h3>
        <p>You have have 3 options to perform a replace</p>
        <ol>
          <li>Click replace all, this will perform the replace on all node</li>
          <li>Replace selected, on each node where you have checked the tick box, when you click replace selected. Ony these nodes will be updated</li>
          <li>Single replace, on each node there is a replace button, when clicked this will only apply to this node</li>
        </ol>
      </Segment>
      {replaceTerm && (
        <Segment basic>
          <SegmentGroup horizontal>
            <Segment basic>
              <Button onClick={onReplaceAll}>Replace All</Button>
              {Boolean(bulkApplyChecked.length) && (
                <Button onClick={onReplaceSelected}>Replace Selected</Button>
              )}
            
            </Segment>
          </SegmentGroup>
        </Segment>
      )}
      <Segment basic>
        {Boolean(results.length) &&
          results.filter(x=> !replaceAppliedList.includes(x.nodeId)).map((row: IFindResult, index: number) => (
            <SearchResultItem
              nodeData={row}
              searchTerm={searchTerm}
              replaceTerm={replaceTerm}
              key={index}
              bulkApplyChecked={bulkApplyChecked}
              onBulkApplyChange={onBulkApplyChange}
              fields={fields}
              markAsReplaced={onReplaceApplied}
            />
          ))}
        {!Boolean(results.length) && <EmptyResultSet />}
      </Segment>
    </>
  );
};

export default connect((state : any,ownProps: any) => { return { currentUser: state.firebase.profile.email, ...ownProps}}, null)(SearchResultList);
