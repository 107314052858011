import { IFindResult } from "../../../../functions/src/http/findandreplace/find";

export interface IFieldAvailable {
  label:string;
  field:string
}

export interface IFileReplace {
  fileName: string;
  filePath: string;
}

export interface IFindAndReplaceInput {
  selectedTenant : string;
  setSearchTerm : (value:string)=>void;
  setReplaceTerm : (value:string | IFileReplace) => void;
  setFields: (value: string[]) => void;
  fields: string[];
  fieldsAvailable: IFieldAvailable[],
  setCaseSensitive: (value:boolean ) => void,
  caseSensitive: boolean,
  searchTerm?: string,
  replaceTerm?: string
}

export interface IBulkApplyItem {
    id: string;
    property: string;
    rawReplace: string;
  }
  
  export  interface             NodePaneProps {
    searchTerm: string;
    nodeData: any;
    replaceTerm?: string | IFileReplace;
    onBulkApplyChange: (id: string) => void;
    bulkApplyChecked: Array<string>;
    fields: string[];
    markAsReplaced?: (id: string) => void;      
    currentUser?: string                             
  }
  export  interface ISearchResultList {
    searchTerm: string;
    replaceTerm?: string | IFileReplace;
    results: Array<IFindResult>;
    fields: string[];
    currentUser?: string;
  }

  export  interface IHightLightResult {
    combined: string;
    searchOnly: string;
    replaceOnly: string;
    rawReplace?: string;
    matchFound: boolean;
  }
  
  export  enum HighLightType {
    Search,
    Replace,
    Combined,
    None,
  }