// Vendor
import React, { Component, createRef } from "react";
import { openModal } from "../modals/modalActions";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Embed,
} from "semantic-ui-react";

// App
import brandConfig from "../../brandConfig.json";
import "./HomePage.css";
import { setEditor } from "../OPtraumapathway/TraumaPathwayContainer/editPathwayActions";
import { setBrowser } from "../OPtraumapathway/TraumaPathwayContainer/setBrowserActions";
import { getDefaultTenantId, hasRole } from "../../app/common/access";
import { SignOut } from "../auth/authActions";
const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const actions = {
  openModal,
  setEditor,
  setBrowser,
  SignOut,
};

const mapState = (state) => ({
  editPathway: state.editPathway,
  profile: state.firebase.profile,
});

const logo = {
  height: "40px",
  width: "auto",
  marginLeft: "0px",
  position: "relative",
  zIndex: "0",
};

const HomepageHeading = ({
  authenticated,
  handleSignIn,
  handleSignOut,
  profile,
}) => {
  return (
    <Segment>
      <Container>
        <Image size="huge" centered src="/assets/logo.png" alt="homepagelogo" />
        <br></br>
        <p style={{ fontSize: "1.5em", color: "rgb(118, 118, 118)" }}>
          <b>Expert decision support at your fingertips</b>
        </p>
        {authenticated ? (
          profile && profile.superadmin ? (
            <Button.Group vertical widths={1}>
              <Button
                as={Link}
                to={brandConfig.pathways.groups[0].url}
                size="huge"
                icon
                labelPosition="right"
                color={brandConfig.main.primaryColor}
                data-testid="getStartedButton"
              >
                Get Started <Icon name="chevron circle right" />
              </Button>
              <Button
                onClick={() => handleSignOut()}
                size="huge"
                icon
                labelPosition="right"
                color="grey"
                data-testid="logoutButton"
              >
                Log Out <Icon name="power" />
              </Button>
            </Button.Group>
          ) : (
            <center>
              <Button.Group vertical widths={1}>
                <Button
                  as={Link}
                  to={brandConfig.pathways.groups[0].url}
                  size="huge"
                  icon
                  labelPosition="right"
                  color={brandConfig.main.primaryColor}
                  data-testid="getStartedButton"
                >
                  Get Started <Icon name="chevron circle right" />
                </Button>
                <Button
                  onClick={handleSignOut}
                  size="huge"
                  icon
                  labelPosition="right"
                  color="grey"
                  data-testid="logoutButton"
                >
                  Log Out <Icon name="power" />
                </Button>
              </Button.Group>
            </center>
          )
        ) : (
          <center>
            <Button
              color={brandConfig.main.primaryColor}
              onClick={handleSignIn}
              size="huge"
              data-testid="loginButton"
            >
              Trust Log In
            </Button>
          </center>
        )}
      </Container>
    </Segment>
  );
};

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const {
      children,
      authenticated,
      handleSignIn,
      handleSignOut,
      handleRegister,
      handleEditPathway,
      profile,
    } = this.props;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Segment
          textAlign="center"
          style={{ minHeight: 350, padding: "1em 0em" }}
          vertical
        >
          <HomepageHeading
            authenticated={authenticated}
            handleSignIn={handleSignIn}
            handleSignOut={handleSignOut}
            handleRegister={handleRegister}
            handleEditPathway={handleEditPathway}
            profile={profile}
          />
        </Segment>
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = { sidebarOpened: false };
  segmentRef = createRef();

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const {
      children,
      authenticated,
      handleSignIn,
      handleSignOut,
      handleRegister,
      handleEditPathway,
    } = this.props;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Segment
          textAlign="center"
          style={{ minHeight: 350, padding: "1em 0em" }}
          vertical
        >
          <HomepageHeading
            mobile
            authenticated={authenticated}
            handleSignIn={handleSignIn}
            handleSignOut={handleSignOut}
            handleRegister={handleRegister}
            handleEditPathway={handleEditPathway}
          />
        </Segment>

        {children}
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({
  children,
  authenticated,
  handleRegister,
  handleSignIn,
  handleSignOut,
  handleEditPathway,
  profile,
}) => (
  <div>
    <DesktopContainer
      authenticated={authenticated}
      handleSignIn={handleSignIn}
      handleSignOut={handleSignOut}
      handleRegister={handleRegister}
      handleEditPathway={handleEditPathway}
      profile={profile}
    >
      {children}
    </DesktopContainer>
    <MobileContainer
      authenticated={authenticated}
      handleSignIn={handleSignIn}
      handleSignOut={handleSignOut}
      handleRegister={handleRegister}
      handleEditPathway={handleEditPathway}
      profile={profile}
    >
      {children}
    </MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

class HomePage extends Component {
  handleSignIn = (history) => {
    this.props.openModal("LoginModal");
  };

  handleRegister = (history) => {
    this.props.openModal("RegisterModal");
  };

  handleSignOut = async () => {
    this.props.SignOut();
    this.props.history.push("/");
    this.handleEditPathway();
    await this.props.setBrowser(false);
  };

  handleEditPathway = () => {
    this.props.setEditor(false);
  };

  state = { sidebarOpened: false, accessibility: false };
  segmentRef = createRef();

  handleAccessibility = () =>
    this.setState({ accessibility: !this.state.accessibility });

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { profile } = this.props;
    const authenticated = profile.isLoaded && !profile.isEmpty;
    const defaultTenantId = authenticated && getDefaultTenantId(profile);
    const allowSettings =
      authenticated &&
      profile.email !== "demo@orthopathway.com" &&
      (hasRole(profile, defaultTenantId, "editor") ||
        hasRole(profile, defaultTenantId, "chiefEditor"));

    const { sidebarOpened } = this.state;
    return (
      <div>
        <Responsive
          getWidth={getWidth}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Menu inverted fixed="top">
            <Menu.Item as={Link} to="/" header>
              <img style={logo} src="/assets/logo.png" alt="logo" />
            </Menu.Item>
          </Menu>
        </Responsive>
        <Responsive
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            vertical
            visible={sidebarOpened}
            onHide={this.handleSidebarHide}
            width="thin"
          >
            {allowSettings && (
              <Menu.Item as="a" onClick={this.handleSettings}>
                <Icon name="cog" />
                Settings
              </Menu.Item>
            )}
          </Sidebar>
          <Menu inverted fixed="top">
            <Menu.Item as={Link} to="/" header>
              <img style={logo} src="/assets/logo.png" alt="logo" />
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item position="right" onClick={this.handleToggle}>
                <Icon name="sidebar" size="large" />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Responsive>
        <ResponsiveContainer
          authenticated={authenticated}
          handleSignIn={this.handleSignIn}
          handleSignOut={this.handleSignOut}
          handleRegister={this.handleRegister}
          handleEditPathway={this.handleEditPathway}
          profile={profile}
        >
          {brandConfig.main.youtubeId && (
            <Segment>
              <Embed
                defaultActive={true}
                autoplay={false}
                id={brandConfig.main.youtubeId}
                source="youtube"
                iframe={{
                  allowFullScreen: true,
                  style: {
                    padding: 10,
                  },
                }}
              />
            </Segment>
          )}
          <Segment style={{ padding: "0em" }} vertical>
            <Grid celled="internally" columns="equal" stackable>
              <Grid.Row textAlign="center">
                <Grid.Column
                  style={{ paddingBottom: "2em", paddingTop: "2em" }}
                >
                  <Segment>
                    <Header
                      as="h3"
                      inverted
                      style={{
                        fontSize: "2em",
                        color: brandConfig.main.primaryRGB,
                      }}
                    >
                      EXPERT CLINICAL DECISION SUPPORT
                    </Header>
                    <Grid celled="internally" columns="equal">
                      <Grid.Column>
                        <Icon.Group>
                          <Icon
                            color={brandConfig.main.primaryColor}
                            name="certificate"
                            size="massive"
                          />
                          <Icon inverted name="doctor" size="huge" />
                        </Icon.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ fontSize: "1.33em" }}>
                          Consultant led decision support at every stage of the
                          patient care pathway. Completely customisable to your
                          institution.
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>
                <Grid.Column
                  style={{ paddingBottom: "2em", paddingTop: "2em" }}
                >
                  <Segment>
                    <Header
                      as="h3"
                      inverted
                      style={{
                        fontSize: "2em",
                        color: brandConfig.main.primaryRGB,
                      }}
                    >
                      IMPROVES PATIENT PATHWAY FLOW
                    </Header>
                    <Grid celled="internally" columns="equal">
                      <Grid.Column>
                        <Icon.Group>
                          <Icon
                            color={brandConfig.main.primaryColor}
                            name="certificate"
                            size="massive"
                          />
                          <Icon inverted name="group" size="huge" />
                        </Icon.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ fontSize: "1.33em" }}>
                          Through directing patients to the right clinic at the
                          right time it can reduce total number of attendances.
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: "0em" }} vertical>
            <Grid celled="internally" columns="equal" stackable>
              <Grid.Row textAlign="center">
                <Grid.Column
                  style={{ paddingBottom: "2em", paddingTop: "2em" }}
                >
                  <Segment>
                    <Header
                      inverted
                      as="h3"
                      style={{
                        fontSize: "2em",
                        color: brandConfig.main.primaryRGB,
                      }}
                    >
                      IMPROVES QUALITY OF CARE
                    </Header>
                    <Grid celled="internally" columns="equal">
                      <Grid.Column>
                        <Icon.Group>
                          <Icon
                            color={brandConfig.main.primaryColor}
                            name="certificate"
                            size="massive"
                          />
                          <Icon inverted name="shield alternate" size="huge" />
                        </Icon.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ fontSize: "1.33em" }}>
                          Increases concordance with local, national and
                          international standards of care. Helps to link
                          clinicians with guidelines when they need them.
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>

                <Grid.Column
                  style={{ paddingBottom: "2em", paddingTop: "2em" }}
                >
                  <Segment>
                    <Header
                      inverted
                      as="h3"
                      style={{
                        fontSize: "2em",
                        color: brandConfig.main.primaryRGB,
                      }}
                    >
                      REDUCES COST
                    </Header>
                    <Grid celled="internally" columns="equal">
                      <Grid.Column>
                        <Icon.Group>
                          <Icon
                            color={brandConfig.main.primaryColor}
                            name="certificate"
                            size="massive"
                          />
                          <Icon inverted name="pound sign" size="huge" />
                        </Icon.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ fontSize: "1.33em" }}>
                          Through streamlining clinic appointments,
                          rationalising splinting devices and avoiding legal
                          fees by increasing the standard of care.
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment>
            <Header
              inverted
              as="h3"
              style={{ fontSize: "2em", color: brandConfig.main.primaryRGB }}
              textAlign="center"
            >
              SUPPORTED BY
            </Header>
            <Container>
              <Grid
                celled="internally"
                textAlign="center"
                padded
                inverted
                stackable
                columns="equal"
              >
                <Grid.Row>
                  <Grid.Column style={{ color: "rgb(118,118,118)" }}>
                    <Image
                      size="small"
                      centered
                      src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/AHSN%20Network.jpg?alt=media&token=9a99a79c-b68e-49f1-89b6-b90baae4b305"
                      alt="nhsLogo"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      size="medium"
                      centered
                      src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/NHS%20Logo.png?alt=media&token=e955cef5-6015-4031-9b40-1cce9db40338"
                      alt="nhsLogo"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      size="small"
                      centered
                      src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/SBRI-healthcare-logo-rgb-medium.jpg?alt=media&token=e257cba5-9ae8-42ff-8c22-8c1d64971a5b"
                      alt="sbriLogo"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>

          <Segment>
            <center>
              <p style={{ fontSize: "1.33em" }}>
                This product {brandConfig.main.title} is supported by SBRI
                Healthcare, an NHS England and NHS Improvement initiative,
                supported by the Academic Health Science Networks (AHSNs).
              </p>
              <p style={{ fontSize: "1.33em" }}>
                Go to:&nbsp;
                <a
                  href="https://www.sbrihealthcare.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  www.sbrihealthcare.co.uk
                </a>
              </p>
            </center>
          </Segment>
          {brandConfig.main.title === "OrthoPathway" && (
            <Segment>
              <center>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  EVIDENCE
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  Click here to see the Independent Health Economic Study of
                  OrthoPathway's Impact in NHS Trusts:&nbsp;
                  <a
                    href="https://drive.google.com/file/d/12xBD4nf5Fc8eTxN-QaDZnomlMIl06Yv_/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Health Economic Study
                  </a>
                </p>
                <p style={{ fontSize: "1.33em" }}>
                  Click here to see the impact of OrthoPathway in Northern Devon
                  Healthcare NHS Trust as presented at ASiT:&nbsp;
                  <a
                    href="https://app.medall.org/posters/3fe632b2-5bef-49e2-b148-0cbbd8f4222f"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    ASiT poster
                  </a>
                </p>
              </center>
            </Segment>
          )}
          <Segment>
            <center>
              <Button
                color={brandConfig.main.primaryColor}
                onClick={this.handleAccessibility}
                size="huge"
                data-testid="loginButton"
              >
                Click Here for Accessibility Statement
              </Button>
            </center>
            {this.state.accessibility && (
              <div>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  ACCESSIBILITY STATEMENT
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  This accessibility statement applies to www.orthopathway.com
                  and www.entpathway.co.uk.
                </p>
                <p style={{ fontSize: "1.33em" }}>
                  This website is run by Primum Digital Limited. We want as many
                  people as possible to be able to use the website. For example,
                  that means you should be able to:
                </p>
                <List bulleted>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      change colours, contrast levels and fonts
                    </p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      zoom in up to 300% without the text spilling off the
                      screen
                    </p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      navigate most of the website using just a keyboard
                    </p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      listen to most of the website using a screen reader
                      (including the most recent versions of JAWS, NVDA and
                      VoiceOver)
                    </p>
                  </List.Item>
                </List>
                <p style={{ fontSize: "1.33em" }}>
                  We’ve also made the website text as simple as possible to
                  understand.
                </p>
                <p style={{ fontSize: "1.33em" }}>
                  <a
                    href="https://mcmw.abilitynet.org.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    AbilityNet
                  </a>{" "}
                  has advice on making your device easier to use if you have a
                  disability.{" "}
                </p>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  FEEDBACK AND CONTACT INFORMATION
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  If you need information on this website in a different format
                  like accessible PDF, large print, easy read, audio recording
                  or braille:
                </p>
                <List bulleted>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      email support@orthopathway.com
                    </p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>call 07960051761</p>
                  </List.Item>
                </List>
                <p style={{ fontSize: "1.33em" }}>
                  We’ll consider your request and get back to you in 2 days.
                </p>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  REPORTING ACCESSIBILITY PROBLEMS WITH THIS WEBSITE
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  We’re always looking to improve the accessibility of this
                  website. If you find any problems not listed on this page or
                  think we’re not meeting accessibility requirements, contact:
                  support@orthopathway.com
                </p>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  ENFORCEMENT PROCEDURE
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  The Equality and Human Rights Commission (EHRC) is responsible
                  for enforcing the Public Sector Bodies (Websites and Mobile
                  Applications) (No. 2) Accessibility Regulations 2018 (the
                  ‘accessibility regulations’). If you’re not happy with how we
                  respond to your complaint,{" "}
                  <a
                    href="https://www.equalityadvisoryservice.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    contact the Equality Advisory and Support Service (EASS)
                  </a>
                </p>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  TECHNICAL INFORMATION ABOUT THIS WEBSITE'S ACCESSIBILITY
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  Primum Digital Limited is committed to making its website
                  accessible, in accordance with the Public Sector Bodies
                  (Websites and Mobile Applications) (No. 2) Accessibility
                  Regulations 2018.
                </p>
                <p style={{ fontSize: "1.33em" }}>
                  This website is fully compliant with the{" "}
                  <a
                    href="https://www.w3.org/TR/WCAG21/"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Web Content Accessibility Guidelines version 2.1
                  </a>{" "}
                  AA standard.{" "}
                </p>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  CONTENT THAT'S NOT WITHIN THE SCOPE OF THE ACESSIBILITY
                  REGULATIONS
                </Header>
                <b>
                  <p style={{ fontSize: "1.33em" }}>
                    CONTENT CREATED BY OUR USERS
                  </p>
                </b>
                <p style={{ fontSize: "1.33em" }}>
                  The platform is designed to be dynamically editable by users.
                  The content created by our users is completely bespoke. We
                  cannot guarantee all the PDFs, documents, videos,
                  illustrations and content meet these requirements. This is the
                  responsibility of the user uploading the content.
                </p>
                <Header
                  inverted
                  as="h3"
                  style={{
                    fontSize: "2em",
                    color: brandConfig.main.primaryRGB,
                  }}
                  textAlign="center"
                >
                  PREPARATION OF THIS ACCESSIBILITY STATEMENT
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  This statement was prepared on 23/4/2020. It was last reviewed
                  on 15/07/2021. This website was last tested on 15/07/2021.
                </p>
                <p style={{ fontSize: "1.33em" }}>
                  The test was carried out by Primum Digital Limited internally.
                  We tested our homepage, landing pages, the pathway editor and
                  node previews within pathways.
                </p>
                <p style={{ fontSize: "1.33em" }}>
                  We initially use the Lighthouse Audit tool get a baseline for
                  user accessablility. This is an automated testing tool created
                  by Google and accessible via the Developer Tools in the Chrome
                  web browser. You can run an accessibility audit yourself on
                  our site using this tool.
                </p>
                <List bulleted>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      Click the three dots on the top right of Google Chrome
                    </p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>Click "More Tools"</p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      Click "Developer Tools"
                    </p>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: "1.33em" }}>
                      Click "Generate Report" - ensure the Accessibility box is
                      checked
                    </p>
                  </List.Item>
                </List>
                <p style={{ fontSize: "1.33em" }}>
                  Lighthouse doesn’t cover all of the WCAG 2.1 standards. Manual
                  testing of the main pages were tested internally referencing
                  the WCAG 2.1 standards following the Lighthouse audit.
                </p>
              </div>
            )}
          </Segment>

          <Segment vertical>
            <Segment>
              <Container>
                <Grid padded divided inverted stackable columns="equal">
                  <Grid.Row>
                    <Grid.Column style={{ color: "rgb(118,118,118)" }}>
                      <div>
                        <Header
                          style={{ color: "rgb(118,118,118)" }}
                          as="h4"
                          content="Contact Us"
                        />
                        <List inverted>
                          <List.Item style={{ color: "rgb(118,118,118)" }}>
                            <Icon
                              style={{ color: "rgb(118,118,118)" }}
                              name="mail"
                              size="large"
                            />
                            {brandConfig.main.email}
                          </List.Item>
                          <List.Item style={{ color: "rgb(118,118,118)" }}>
                            <Icon
                              style={{ color: "rgb(118,118,118)" }}
                              name="phone square"
                              size="large"
                            />{" "}
                            07960051761
                          </List.Item>
                        </List>
                        <Header
                          style={{ color: "rgb(118,118,118)" }}
                          as="h4"
                          content="Legal"
                        />
                        <b>
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/OrthoPathway%20Privacy%20Policy.pdf?alt=media&token=df4a1052-62e7-4be5-99ae-8eab5401407d"
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            Privacy Policy
                          </a>
                        </b>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <Header
                        style={{ color: "rgb(118,118,118)" }}
                        as="h4"
                        content="Primum Digital Ltd"
                      />
                      <List inverted>
                        <List.Item style={{ color: "rgb(118,118,118)" }}>
                          Harscombe House
                        </List.Item>
                        <List.Item style={{ color: "rgb(118,118,118)" }}>
                          1 Darklake View
                        </List.Item>
                        <List.Item style={{ color: "rgb(118,118,118)" }}>
                          Estover
                        </List.Item>
                        <List.Item style={{ color: "rgb(118,118,118)" }}>
                          Plymouth
                        </List.Item>
                        <List.Item style={{ color: "rgb(118,118,118)" }}>
                          PL6 7TL
                        </List.Item>
                        <List.Item style={{ color: "rgb(118,118,118)" }}>
                          United Kingdom
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <p style={{ color: "rgb(118,118,118)" }}>
                        Registered in England and Wales
                      </p>
                      <p style={{ color: "rgb(118,118,118)" }}>
                        Company No: 12064793
                      </p>
                      <p style={{ color: "rgb(118,118,118)" }}>
                        VAT No: 339 4606 81
                      </p>
                      {brandConfig.main.title === "OrthoPathway" && (
                        <p style={{ color: "rgb(118,118,118)" }}>
                          <b>
                            Primum Digital Ltd acknowledges the support received
                            by University Hospitals Plymouth NHS Trust in the
                            development of OrthoPathway.
                          </b>
                        </p>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        size="small"
                        centered
                        src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/cyberessentials_certification%20mark_colour%20.png?alt=media&token=c58bee5c-3db0-42d9-8665-a213c1bcd1f5"
                        alt="sbriLogo"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Segment>
          </Segment>

          <Segment attached="bottom">
            <div style={{ textAlign: "center" }}>
              <p>
                <Icon name="copyright" />
                Copyright 2018-{(new Date()).getFullYear()} Primum Digital Ltd
              </p>
            </div>
          </Segment>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default withRouter(withFirebase(connect(mapState, actions)(HomePage)));
