import React, { useEffect } from "react";
import { withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import {
  getAvailableTenants,
  getDefaultTenantId,
} from "../../../app/common/access";
import SearchableDropdown from "./SearchableDropdown";

interface ITenantSelectProps {
  firebase: any;
  onTenantSelect: (data: string) => void;
}

const TenantDropdown = ({ firebase, onTenantSelect }: ITenantSelectProps) => {
  const tenants = getAvailableTenants(firebase.profile);
  const defaultTenantId = getDefaultTenantId(firebase.profile);

  if (!Boolean(tenants)) {
    throw Error("User has no tenants");
  }

  const [selectedTenant, setSelectedTenant] = React.useState<string>(
    defaultTenantId || ""
  );

  const dropDownData = [
    {
      name: "Tenants",
      items: Object.keys(tenants).map((x) => {
        return { name: x, displayName: x };
      }),
    },
  ];

  useEffect(() => {
    onTenantSelect(selectedTenant);
  }, [selectedTenant, onTenantSelect]);

  if (Object.keys(tenants).length) {
    return (
      <>
        <label>Selected Tenant</label>
        <span>{defaultTenantId}</span>
      </>
    );
  }

  return (
    <>
      <label>Selected Tenant</label>
      <SearchableDropdown
        placeholder={"Select a tenant"}
        dropdownData={dropDownData}
        onSelection={(data) => setSelectedTenant(data)}
        value={selectedTenant}
      />
    </>
  );
};

export default withFirestore(connect((state) => state)(TenantDropdown));
