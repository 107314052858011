//Copyright 2018 Nathan Moore

import { SET_PATHWAY } from "./pathwayConstants";
import { createReducer } from "../../../app/common/util/reducerUtil";

const initialState = "";

export const setPathway = (state, payload) => {
  const newPathway = payload;
  return newPathway;
};

export default createReducer(initialState, {
  [SET_PATHWAY]: setPathway,
});
