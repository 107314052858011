import React from "react";
import { Segment, Icon, Image, List } from "semantic-ui-react";
import draftToHtml from "draftjs-to-html";
import ReactHtmlParser from "react-html-parser";
import { convertToRaw } from "draft-js";
import moment from "moment";
import sanitizeHtml from "sanitize-html";

const center = {
  textAlign: "center",
};

const logo = {
  height: "70px",
  width: "auto",
  marginLeft: "0px",
  position: "absolute",
  zIndex: "0",
};

const westDevonMIULogo = {
  height: "100px",
  width: "auto",
  marginLeft: "650px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const rdeLogo = {
  height: "75px",
  width: "auto",
  marginLeft: "560px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const plymouthLogo = {
  height: "100px",
  width: "auto",
  marginLeft: "480px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const rchLogo = {
  height: "80px",
  width: "auto",
  marginLeft: "480px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const cornwallMIULogo = {
  height: "80px",
  width: "auto",
  marginLeft: "570px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const northDevonLogo = {
  height: "80px",
  width: "auto",
  marginLeft: "550px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const patientLabel = {
  height: "140px",
  width: "auto",
  marginLeft: "420px",
  marginTop: "85px",
  position: "absolute",
  zIndex: "0",
};

class ComponentToPrint extends React.Component {
  render() {
    const date = moment.utc().toDate();
    const localdate = moment(date).local().format("DD-MM-YYYY HH:mm:ss");
    const {
      decisionSummary,
      tenantId,
      node,
      editorState,
      newPathway
    } = this.props;
    let html =
      editorState &&
      sanitizeHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    return (
      <Segment>
        <Image style={logo} src="/assets/logo.png" alt="logo" />
        {tenantId === "West Devon MIU" && (
          <Image
            style={westDevonMIULogo}
            src="https://firebasestorage.googleapis.com/v0/b/orthopathwaytestdatabase.appspot.com/o/West%20Devon%20MIU%2Flivewell%20logo.png?alt=media&token=3e1772e3-a548-4aa2-a45e-98d1ab329445"
            alt="logo"
          />
        )}
        {tenantId === "Exeter" && (
          <Image
            style={rdeLogo}
            src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/Exeter%2FRDE%20logo.png?alt=media&token=3a6871ce-56d2-42a9-942d-3c4703058355"
            alt="logo"
          />
        )}
        {tenantId === "Derriford" && (
          <Image
            style={plymouthLogo}
            src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/plymouthFlatLogo.png?alt=media&token=b4abb048-5802-48cf-af79-189d8d908830"
            alt="logo"
          />
        )}
        {tenantId === "Cornwall MIU" && (
          <Image
            style={cornwallMIULogo}
            src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/cornwallMIUlogo.png?alt=media&token=11af1ebc-54d0-4e15-a995-97ac04bcfc21"
            alt="logo"
          />
        )}
        {tenantId === "North Devon" && (
          <Image
            style={northDevonLogo}
            src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/ndht-logo.jpg?alt=media&token=c228d732-593b-40cb-8fb9-982bd2a22115"
            alt="logo"
          />
        )}
        {tenantId === "RCH" && (
          <Image
            style={rchLogo}
            src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/rchtlogo.png?alt=media&token=510820ce-9975-4780-9644-60da7bcdbe2a"
            alt="logo"
          />
        )}
        <Image
          style={patientLabel}
          src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/patientLabel.png?alt=media&token=23d5caec-4008-40ad-a70d-ef4c31aa7e89"
          alt="logo"
        />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Segment compact>
          <h3>Date: {localdate} </h3>
        </Segment>

        <br></br>

        <Segment.Group>
          <Segment style={center} compact>
            <h2>
              {tenantId} {newPathway} Pathway
            </h2>
          </Segment>
          <Segment compact>
            <h4>Decision Summary</h4>
          </Segment>
          <Segment compact>
            <List>
              {decisionSummary &&
                decisionSummary.map(
                  (decision, index) =>
                    decision !== "Continue" &&
                    decision !== "Continue to full pathway" &&
                    decision !== "Yes" &&
                    decision !== "No" &&
                    decision !== "continue" &&
                    decision !== "continue to full pathway" &&
                    decision !== "no" &&
                    decision !== "yes" && (
                      <List.Item key={index}>
                        <Icon name="caret right" /> {decision}
                      </List.Item>
                    )
                )}
            </List>
          </Segment>
          <Segment compact>
            <h4>Outcome</h4>
          </Segment>
          <Segment>{node.title}</Segment>
          <Segment compact>
            <h4>Management plan</h4>
          </Segment>
          {node.nodeContentArea && (
            <Segment compact>
              <div>{ReactHtmlParser(html)}</div>
            </Segment>
          )}
        </Segment.Group>
        <Segment>
          <p>
            <Icon size="big" name="exclamation triangle" />
            <b>
              This patient was assessed and treated during the coronavirus
              pandemic
            </b>
          </p>
        </Segment>
        <Segment>
          <p>
            Healthcare Professional Name
            (Print):...........................................
          </p>
          <br></br>
          <p>
            Healthcare Professional
            Signature:...................................................
          </p>
        </Segment>
      </Segment>
    );
  }
}

export default ComponentToPrint;
