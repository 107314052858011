//Copyright 2018 Nathan Moore

export const SIGN_IN_USER = "SIGN_IN_USER";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const MFA_REQUIRED = "MFA_REQUIRED";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const FORGOTTEN_PASSWORD = "FORGOTTEN_PASSWORD";
export const SIGN_STARTED = "SIGN_STARTED";
export const MFA_REGISTATION_ATTEMPT = "MFA_REGISTATION_ATTEMPT";
export const MFA_UPDATE = "MFA_UPDATE";

export const SIGN_IN_STATE_IDLE = "SIGN_IN_STATE_IDLE";
export const SIGN_IN_STATE_ERROR = "SIGN_IN_STATE_ERROR";
export const SIGN_IN_STATE_INPROCESS = "SIGN_IN_STATE_INPROCESS";
export const SIGN_IN_STATE_MFA_REQUIRED = "SIGN_IN_STATE_MFA_REQUIRED";
export const SIGN_IN_STATE_COMPLETE = "SIGN_IN_STATE_COMPLETE";
