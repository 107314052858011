import React, { useState } from "react";
import { Image, Modal, Segment, Icon, Button, Grid } from "semantic-ui-react";
import UploadImg from "./UploadImg";

export default function ImgComponent({
  galleryElement,
  isSuperAdmin,
  pullData,
  cropperRef,
  addImgToGallery,
}) {
  const { imageName, imageURL } = galleryElement;
  const [enlargeImg, setEnlargeImg] = useState(false);
  const handleEnlargeImg = () => {
    setEnlargeImg(!enlargeImg);
  };
  return (
    <div>
      <Segment style={{ height: "350px", width: "355px" }}>
        <center>
          <b>{imageName}</b>
          <Modal
            size={"large"}
            centered
            dimmer={"blurring"}
            open={enlargeImg}
            onClose={handleEnlargeImg}
            trigger={
              <div>
                <p>
                  Click image to enlarge <Icon name="search plus" />
                </p>
                <Image
                  style={{ maxHeight: "180px", width: "auto" }}
                  src={imageURL}
                  onClick={handleEnlargeImg}
                />
              </div>
            }
          >
            {enlargeImg && (
              <>
                <Modal.Header>
                  <Grid>
                    <Button
                      onClick={handleEnlargeImg}
                      floated="right"
                      color="grey"
                    >
                      X
                    </Button>
                  </Grid>
                </Modal.Header>
                <Modal.Content>
                  <center>
                    <Image
                      src={imageURL}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "500px",
                        cursor: "pointer",
                      }}
                    />
                  </center>
                </Modal.Content>
              </>
            )}
          </Modal>
        </center>
        {isSuperAdmin ? (
          <UploadImg
            galleryElement={galleryElement}
            type="update"
            isSuperAdmin={isSuperAdmin}
            cropperRef={cropperRef}
            addImgToGallery={addImgToGallery}
          />
        ) : (
          <UploadImg
            galleryElement={galleryElement}
            isSuperAdmin={isSuperAdmin}
            pullData={pullData}
          />
        )}
      </Segment>
    </div>
  );
}
