import { TObject } from "../types/common";

interface IResolvePath {
  (object: TObject, path: string, defaultValue?: any): string | undefined;
}

/**
 * Attempts to resolve path against object and dot notation to a path and attempts to return the resolved value.
 * @param object Object to search
 * @param path string of a period seperated path to a particular key
 * @param defaultValue default vlaue to return if not found, default undefined
 */
const resolvePath: IResolvePath = (object, path, defaultValue = undefined) =>
  path.split(".").reduce((o: any, p) => (o ? o[p] : defaultValue), object);

export default resolvePath;
