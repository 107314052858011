// App
import { createReducer } from "../../app/common/util/reducerUtil";
import {
  EPISODES_ENTER_NODE,
  EPISODES_EXIT_PATHWAY,
  EPISODES_UPLOAD_SUCCESS,
} from "./episodesConstants";

// Types
import { IEpisodesState, IEpisodeNode } from "./episodesTypes";
import { INode } from "../../app/types/nodes";

const initialState: IEpisodesState = {
  currentPathwayEpisode: false,
  currentPathwayDocID: false,
};

/**
 * Fired when entering a pathway to trigger timestamp generation for a new pathway journey
 */
export const episodesEnterNode = (
  state: IEpisodesState,
  payload: {
    pathway: string;
    node: INode;
    entryTimestamp: string;
  }
) => {
  const newNode: IEpisodeNode = {
    nodeSnapshot: payload.node,
    entryTimestamp: payload.entryTimestamp,
  };

  // If we are already tracking an episode in this pathway, add the new node to the current pathway.
  if (
    state.currentPathwayEpisode &&
    payload.pathway === state.currentPathwayEpisode.pathway
  ) {
    const currentNodes = state.currentPathwayEpisode.nodes;

    // Check if the current entered node is the same as the final node currently tracked, if so, skip it to reduce duplications.
    if (
      payload.node.id === currentNodes[currentNodes.length - 1].nodeSnapshot.id
    ) {
      return { ...state };
    }

    return {
      ...state,
      currentPathwayEpisode: {
        ...state.currentPathwayEpisode,
        nodes: [...currentNodes, newNode],
      },
    };
  }
  // Otherwise its a new episode and we can reset the state
  return {
    ...state,
    currentPathwayEpisode: { pathway: payload.pathway, nodes: [newNode] },
  };
};

/**
 * Fired when completing a pathway journey and resetting state for the current pathway journey
 */
export const episodesExitPathway = (state: IEpisodesState) => {
  return {
    ...state,
    currentPathwayEpisode: initialState.currentPathwayEpisode,
    currentPathwayDocID: initialState.currentPathwayDocID,
  };
};

/**
 * Fired when we've uploaded to firebase
 */
export const episodesUploadSuccess = (state: IEpisodesState, id: string) => {
  return {
    ...state,
    currentPathwayDocID: id,
  };
};

export default createReducer(initialState, {
  [EPISODES_ENTER_NODE]: episodesEnterNode,
  [EPISODES_EXIT_PATHWAY]: episodesExitPathway,
  [EPISODES_UPLOAD_SUCCESS]: episodesUploadSuccess,
});
