import React from "react";
import { Dropdown } from "semantic-ui-react";

export const arrayGroups = [
  {
    text: "All Images",
    value: "allImages",
  },
  {
    text: "All Lower Limb",
    value: "lowerLimb",
  },
  {
    text: "All Upper Limb",
    value: "upperLimb",
  },
  {
    text: "All Fractures",
    value: "fracture",
  },
  {
    text: "All Trauma",
    value: "trauma",
  },
  {
    text: "All Elective",
    value: "elective",
  },
  {
    text: "Adult Fractures",
    value: "adultFractures",
  },
  {
    text: "Paediatric Fractures",
    value: "paediatricFractures",
  },
  {
    text: "Foot and Ankle",
    value: "footAndAnkle",
  },
  {
    text: "Hand",
    value: "hands",
  },
  {
    text: "Hip",
    value: "hip",
  },
  {
    text: "Knee",
    value: "knee",
  },
  {
    text: "Pelvis",
    value: "pelvis",
  },
  {
    text: "Shoulder and Elbow",
    value: "shoulderAndElbow",
  },

  {
    text: "Paediatrics",
    value: "paediatrics",
  },

  {
    text: "Spine",
    value: "spine",
  },
  {
    text: "Signpost",
    value: "sign",
  },
];
export default function ImageGroupSelector({ onImageGroupChange }) {
  const setCriteria = (e, { value }) => {
    onImageGroupChange(value);
  };
  return (
    <Dropdown
      placeholder="Groups"
      onChange={setCriteria}
      search
      selection
      options={arrayGroups}
    />
  );
}
