import { Checkbox, Form, Grid } from "semantic-ui-react";
import { IFindAndReplaceInput } from "./types";





const TextReplace = ({ setSearchTerm, setReplaceTerm, setFields, fields, fieldsAvailable: fieldsAvalible, setCaseSensitive, caseSensitive} : IFindAndReplaceInput)=>{



    return (
        <>
        <Grid.Row>
        <Grid.Column>
          <Form.Group grouped>
            <label>Fields to search</label>
            {fieldsAvalible.map((field, index) => {
              return (

                <Form.Field  key={`Fields[${field.field}]`}>
                    <Checkbox
                     
                      label={field.label}
                      control="input"
                      type="checkbox"
                      value={field.field}
                      name={`Fields[${index}]`}
                      checked={fields.some(x=> x === field.field)}
                      onChange={async (e, { value, checked }) => {
                        const otherValues = fields.filter(
                          (v) => v !== value
                        ) as any;
                        if (checked) {
                          otherValues.push(value);
                        }
                        setFields(otherValues);
                      }}
                    />
                </Form.Field>
              );
            })}
          </Form.Group>
        </Grid.Column>
        <Grid.Column>
          <Form.Group grouped>
            <label>Search options</label>
            <Form.Field
              label="Case sensitive"
              control="input"
              type="radio"
              value="casesensitive"
              name="findtype"
              onChange={async (e: any) => {
                setCaseSensitive(true);
              }}
              checked={caseSensitive}
            />
            <Form.Field
              label="Case insensitive"
              control="input"
              type="radio"
              value="caseinsensitive"
              name="findtype"
              onChange={async (e: any) => {
                setCaseSensitive(false);
              }}
              checked={!caseSensitive}
            />
          </Form.Group>
          <hr />
          
        </Grid.Column>
      </Grid.Row>
        <Grid.Row>
        <Grid.Column>
            <Form.Input
              label="Find"
              name="SearchTerm"
              placeholder="Search Term"
              id="SearchTerm"
              onChange={async (e: any, { name, value }: any) => {
                setSearchTerm(value);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Replace"
              name="ReplaceTerm"
              placeholder="Replace Term"
              id="ReplaceTerm"
              onChange={async (e: any, { name, value }: any) => {
                setReplaceTerm(value);
              }}
            />
            </Grid.Column>
      </Grid.Row>
      </>
    )

}


export default TextReplace