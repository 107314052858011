import { HighLightType, NodePaneProps } from "./types";
import { Grid } from "semantic-ui-react";
import PathwayRecentActivityNodePreview from "../PathwayRecentActivity/PathwayRecentActivityNodePreview";
import { replaceInObject } from "./Shared";

/* tslint:disable */
const JSPathwayRecentActivityNodePreview: any =
  PathwayRecentActivityNodePreview; //todo delete me after refactoring to TS
/* tslint:enable */

const CombinedResultAsPreview = ({
    searchTerm,
    replaceTerm,
    nodeData,
    onBulkApplyChange,
    bulkApplyChecked,
    fields
  }: NodePaneProps) => {
    const node = JSON.parse(JSON.stringify(nodeData.nodeContent));
    if(typeof replaceTerm === "string"){
      replaceInObject(node, searchTerm, replaceTerm, fields, HighLightType.Combined);
    }

  /*
    const onApply = async (property: string, nodeId: string, content: string) => {
      setSaving(true);
      const nodeReference = firebase
        .firestore()
        .collection(nodeData.nodePath)
        .doc(nodeData.nodeId);
      const obj = {} as any;
      obj[property] = content;
      await nodeReference.update(obj);
      setSaving(false);
    };
  */
    return (
      <Grid columns="equal">
        <Grid.Row columns={2}>
          <Grid.Column>
            <JSPathwayRecentActivityNodePreview
              tenantId={""}
              updatedNode={true}
              allowHtml={true}
              node={node}
              pathway={nodeData.nodePath}
              typeOfNodePreview={"Node before Update"}
            />
          </Grid.Column>
        </Grid.Row>
        {/* Object.keys(nodeData).filter(x=> x !== "nodeId" && x !== "nodePath" && x !=='nodeContent' ).map((property : string) => {
                              const item = highlightSearchTerm(searchTerm,nodeData[property], replaceTerm)
                              return ( 
                                  
                                  <Grid.Row key={property}>
                                      <Grid.Column>
                                          {property}
                                      </Grid.Column>
                                      <Grid.Column>
                                          <div dangerouslySetInnerHTML={{__html: item.combined }}></div>
                                      </Grid.Column>
                                      <Grid.Column>
                                          {
                                               replaceTerm && item.matchFound &&
                                               <Checkbox value={nodeData.nodeId} checked={bulkApplyChecked.some(x=> x.id === nodeData.nodeId && x.property === property)} onChange={(e, { value }) => onBulkApplyChange(value as string, property, item.rawReplace || "")} />
                                          }
                                      </Grid.Column>   
                                      <Grid.Column>  
                                          {
                                          replaceTerm && item.matchFound &&
                                          <div style={{ display:"inline-block" }}><Button loading={saving} onClick={()=> onApply(property, nodeData.nodeId, item.rawReplace || "" )}>Apply</Button></div>
                                          }
                                      </Grid.Column>
                                  </Grid.Row>
                                  )
                              }
                          )
                          */}
      </Grid>
    );
  };

  export default CombinedResultAsPreview