import React, { useState } from "react";
import { Form, Grid } from "semantic-ui-react";
import SearchResultList from "./SearchResultList";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import functions from "../../../app/common/util/functions";
import { IFindResult } from "../../../../functions/src/http/findandreplace/find";
import PathwayDropdown from "../../../app/common/form/PathwayDropdown";
import TenantDropdown from "../../../app/common/form/TenantDropdown";
import { useEffect } from "react";
import TextReplace from "./TextReplace";
import ImageReplace from "./ImageReplace";
import FileReplace from "./FileReplace";
import { IFieldAvailable, IFileReplace } from "./types";




const fieldsAvailable : Array<IFieldAvailable> = [
  { label: "Title", field: "title" },
  { label: "Content", field: "nodeContentArea" },
  { label: "Question", field: "question"},
  { label: "Answer/Link name", field: "answers"},
  { label: "Logic Fields", field: "logicfields"},
  { label: "Logic Group Title", field: "groupNames"},
  { label: "Logic Link Name", field: "ruleNames"}
];



const FindForm = (props: any) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [replaceTerm, setReplaceTerm] = React.useState<string | IFileReplace>("");
  
  const [fields, setFields] = React.useState<string[]>(fieldsAvailable.map(field => field.field));
  const [searchResults, setSearchResult] = React.useState<IFindResult[]>();
  const [pathways, setPathways] = React.useState<string[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string>("")
  const [loading, setLoading] = React.useState<boolean>(false);
  const [caseSensitive, setCaseSensitve] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false)
  const [searchType,setSearchType] = useState<string>("textsearch")

  useEffect(() => {

    setSearchTerm("")
    setReplaceTerm("")

  },[searchType])

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      setLoaded(false);

   
      const result = await functions.nodeSearch({
        searchTerms: [
          {
            term: searchTerm,
            type: caseSensitive ? "casesensitive" : "caseinsensitive",
          },
        ],
        pathways: pathways,
        columns: fields.filter((x) => Boolean(x)),
        tenantId: selectedTenant
      });

      setLoaded(true)
      setSearchResult(result);
      setLoading(false);
    } catch (ex: any) {
      console.log(ex.message);
    }
  };

  useEffect(()=>{
    setSearchResult([])
    setLoaded(false)
  },[searchTerm, searchType])

  const onTenantSelect = (data: string) => setSelectedTenant(data);

  const onPathwaySelect = React.useCallback((data: string[]) => {
    setPathways(data.map((x : any)=> `${selectedTenant}/${x}/nodes`));
   
  },[selectedTenant]);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group widths="equal">
          <Form.Field>
              <TenantDropdown onTenantSelect={onTenantSelect} />
            
          </Form.Field>
            <Form.Field>
                <PathwayDropdown onPathwaySelectMultiple={onPathwaySelect} placeholder="All Pathways" allowMultiple  />
            </Form.Field>
        </Form.Group>
        <Form.Group inline >
                <label>Search type</label>
                <Form.Field
                  label="Text search"
                  control="input"
                  type="radio"
                  value="textsearch"
                  name="searchtype"
                  onChange={async (e: any) => {
                    setSearchType("textsearch");
                  }}
                  checked={searchType === "textsearch"}
                />
                <Form.Field
                  label="File search"
                  control="input"
                  type="radio"
                  value="filesearch"
                  name="searchtype"
                  onChange={async (e: any) => {
                    setSearchType("filesearch");
                  }}
                  checked={searchType === "filesearch"}
                />
                <Form.Field
                  label="Image search"
                  control="input"
                  type="radio"
                  value="imagesearch"
                  name="searchtype"
                  onChange={async (e: any) => {
                    setSearchType("imagesearch");
                  }}
                  checked={searchType === "imagesearch"}
                />
              </Form.Group>
        
        <Grid columns={2} divided>
        
         {searchType === "textsearch" && <TextReplace selectedTenant={selectedTenant} setSearchTerm={setSearchTerm} setReplaceTerm={setReplaceTerm} setFields={setFields} fields={fields} fieldsAvailable={fieldsAvailable} setCaseSensitive={setCaseSensitve} caseSensitive={caseSensitive}  /> }
         {searchType === "imagesearch" && <ImageReplace selectedTenant={selectedTenant} setSearchTerm={setSearchTerm} setReplaceTerm={setReplaceTerm} setFields={setFields} fields={fields} fieldsAvailable={fieldsAvailable} setCaseSensitive={setCaseSensitve} caseSensitive={caseSensitive} searchTerm={searchTerm} replaceTerm={replaceTerm as string} /> }
         {searchType === "filesearch" && <FileReplace selectedTenant={selectedTenant} setSearchTerm={setSearchTerm} setReplaceTerm={setReplaceTerm} setFields={setFields} fields={fields} fieldsAvailable={fieldsAvailable} setCaseSensitive={setCaseSensitve} caseSensitive={caseSensitive} /> }
          <Grid.Row>
            <Grid.Column>
                <Form.Button content="Find" loading={loading} disabled={!Boolean(searchTerm)} />
            </Grid.Column>  
          </Grid.Row>
        </Grid>
      </Form>

      {loaded && searchResults && (
        <SearchResultList
          searchTerm={searchTerm}
          replaceTerm={replaceTerm}
          results={searchResults}
          fields={fields}
        />
      )}
    </>
  );
};

export default withFirestore(connect((state) => state)(FindForm));
