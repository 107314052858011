import React from 'react'
import PageContainer from '../../../app/layout/PageContainer'
import FindForm from './FindForm'


const FindAndReplacePage  = () => {

    return (
        <PageContainer  title="Find and Replace">
            <FindForm />
        </PageContainer>

    )
}

export default FindAndReplacePage