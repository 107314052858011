import { SET_BROWSER } from "./setBrowserConstants";
import { createReducer } from "../../../app/common/util/reducerUtil";

const initialState = false;

export const setBrowser = (state, payload) => {
  return payload;
};

export default createReducer(initialState, {
  [SET_BROWSER]: setBrowser,
});
