// Vendor
import React from "react";
import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

// App
import { TObject } from "../types/common";
import resolvePath from "../utils/resolvePath";

// Types
type TActionFunc = () => {};

type IStatePath = {
  title: string;
  path: string;
  resolver?: (state: any) => any;
  defaultValue?: any;
};

interface IFirebaseQuery {
  collection: string;
  doc?: string;
  subcollections?: IFirebaseQuery[];
}

interface IListenerQuery extends IFirebaseQuery {
  storeAs: string;
}

interface IActionsFuncs {
  [key: string]: TActionFunc;
}

interface IFireStoreContainerProps {
  actions?: IActionsFuncs;
  state?: IStatePath[] | (() => {});
  listeners?: IListenerQuery[];
  children: ReactNode;
  [key: string]: any;
}

// Map State
const mapState = (state: TObject, paths: IStatePath[]) => {
  const stateToReturn = paths.reduce((acc: TObject, statePath: IStatePath) => {
    const resolvedState = resolvePath(
      state,
      statePath.path,
      statePath.defaultValue
    );
    if (resolvedState) {
      const finalState = statePath.resolver
        ? statePath.resolver(resolvedState)
        : resolvedState;
      return {
        ...acc,
        [statePath.title]: finalState,
      };
    }

    return acc;
  }, {});

  return stateToReturn;
};

// A component to receive firestore and redux props, subscribe to elements automatically and pass them to the children.
// Removes the Verboseness of dealing with Firestore diretly in containers where possible.
class FireStoreContainerRenderer extends Component<IFireStoreContainerProps> {
  async componentDidMount() {
    const { firestore, listeners } = this.props;
    if (listeners) {
      listeners.forEach(async (query) => {
        await firestore.setListener(query);
      });
    }
  }

  async componentWillUnmount() {
    const { firestore, listeners } = this.props;
    if (listeners) {
      listeners.forEach(async (query) => {
        await firestore.unsetListener(query);
      });
    }
  }

  render() {
    const { listeners, state, actions, children, ...rest } = this.props;

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { ...rest });
        }
        return child;
      }
    );

    return <>{childrenWithProps}</>;
  }
}

// Wrap our component in a wrapper that'll link with Redux and firestore.
const FireStoreContainer = ({
  actions,
  state,
  listeners,
  children,
}: IFireStoreContainerProps) => {
  const ContainerComponent = (...props: any[]) => (
    <FireStoreContainerRenderer listeners={listeners} {...props[0]}>
      {children}
    </FireStoreContainerRenderer>
  );

  // Check whether we're using an array of dotnotation accessors, or a classic state function.
  const stateForRedux = Array.isArray(state)
    ? (reduxState: any) => mapState(reduxState, state)
    : state;

  const ConnectedComponent = connect(
    stateForRedux,
    actions || null
  )(firestoreConnect()(ContainerComponent));
  return <ConnectedComponent />;
};

export default FireStoreContainer;
