import { Grid, SemanticCOLORS } from "semantic-ui-react";
import brandConfig from "../../../brandConfig.json";

const EmptyResultSet = () => (
    <Grid columns="equal" textAlign="center">
      <Grid.Row color={brandConfig.main.primaryColor as SemanticCOLORS}>
        <h5>Not results found</h5>
      </Grid.Row>
    </Grid>
  );

export default EmptyResultSet