import { SET_COLLECTION } from "./setCollectionConstants";
import { createReducer } from "../../app/common/util/reducerUtil";

const initialState = "";

export const setCollection = (state, payload) => {
  const newCollection = payload;
  return newCollection;
};

export default createReducer(initialState, {
  [SET_COLLECTION]: setCollection,
});
