//Copyright 2018 Nathan Moore

import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import nodeReducer from "../../features/pathwaybuilder/nodeReducer";
import { reducer as FormReducer } from "redux-form";
import modalsReducer from "../../features/modals/modalReducer";
import authReducer from "../../features/auth/authReducer";
import asyncReducer from "../../features/async/asyncReducer";
import pathwayReducer from "../../features/OPtraumapathway/TraumaPathwayContainer/pathwayReducer";
import editPathwayReducer from "../../features/OPtraumapathway/TraumaPathwayContainer/editPathwayReducer";
import sideBarReducer from "../../features/nav/NavBar/sideBarReducer";
import setBrowserReducer from "../../features/OPtraumapathway/TraumaPathwayContainer/setBrowserReducer";
import setCollectionReducer from "../../features/modals/setCollectionReducer";
import episodesReducer from "../../features/episodes/episodesReducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: FormReducer,
  nodes: nodeReducer,
  modals: modalsReducer,
  auth: authReducer,
  async: asyncReducer,
  toastr: toastrReducer,
  currentPathway: pathwayReducer,
  editPathway: editPathwayReducer,
  sideBar: sideBarReducer,
  browser: setBrowserReducer,
  collection: setCollectionReducer,
  episodes: episodesReducer,
});

export default rootReducer;
