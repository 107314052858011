export type TEditableRoles = "editor" | "chiefEditor";
export type TRoles = TEditableRoles | "readonly";

interface IAccess {
  role: TRoles;
  id: string;
  default?: boolean;
}

export interface IProfile {
  uid: string;
  superadmin?: boolean;
  access: {
    [key: string]: IAccess;
  };
  email: string;
  isEmpty: boolean;
  isLoaded: boolean;
  hasActivatedAccount: boolean;
}

/**
 * Determines whether this profile has edit access to this tenants content
 * @param {object} profile user profile from firebase state
 * @param {string} tenantId string of tenantId we are checking access to
 * @return {bool} whether we have access or not
 */
const canEdit = (profile: IProfile, tenantId: string) => {
  if (!profile.isLoaded) return false;
  if (profile.superadmin) return true;

  const rolesThatCanEdit = ["editor", "chiefEditor"];
  return (
    profile.access[tenantId] &&
    rolesThatCanEdit.includes(profile.access[tenantId].role)
  );
};

/**
 * Check a user has a specific role on this TenantId
 * @param {object} profile user profile from firebase state
 * @param {string} tenantId string of tenantId we are checking access to
 * @param {string} role role to check for
 * @return {bool} whether we have access or not
 */
const hasRole = (
  profile: IProfile,
  tenantId: string,
  role: TRoles | "superadmin"
) => {
  if (!profile.isLoaded) return false;
  if (profile.superadmin && role === "superadmin") return true;

  return profile.access[tenantId] && profile.access[tenantId].role === role;
};

/**
 * Retreive a users role in a certain tenancy
 * @param {object} profile user profile from firebase state
 * @param {string} tenantId string of tenantId we are checking access to
 * @return {string | false} role they have or false
 */
const getRole = (profile: IProfile, tenantId: string): TRoles | false => {
  if (!profile.isLoaded) return false;
  if (profile.access[tenantId]) return profile.access[tenantId].role;
  return false;
};

/**
 * Get access rules for default tenant
 * @param {object} profile user profile from firebase state
 * @return {string} name of defalt tenant
 */
const getDefaultTenantId = (profile: IProfile) => {
  if (!profile.isLoaded || !profile.access) return false;
  return Object.values(profile.access).find((tenant) => tenant.default)?.id;
};

const getAvailableTenants = (profile: IProfile) => {
  if (!profile.isLoaded || !profile.access) return false;
  return profile.access;
};

export { canEdit, getDefaultTenantId, hasRole, getRole, getAvailableTenants };
