// Vendor
import React, { Component } from "react";
import {
  Segment,
  Button,
  Image,
  Icon,
  Embed,
  Modal,
  List,
  Grid,
  Header,
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import ReactHtmlParser from "react-html-parser";
import { convertToRaw } from "draft-js";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import sanitizeHtml from "sanitize-html";

// App
import brandConfig from "../../../brandConfig.json";
import { openModal } from "../../modals/modalActions";
import ComponentToPrint from "../NodePreview/NodePrint";

const actions = {
  openModal,
};

const center = {
  textAlign: "center",
};

const image = {
  height: "auto",
  maxHeight: "350px",
  maxWidth: "100%",
  cursor: "pointer",
};

const modalImage = {
  height: "auto",
  maxWidth: "100%",
  maxHeight: "500px",
  cursor: "pointer",
  textAlign: "center",
};

class PathwayRecentActivityNodePreview extends Component {
  constructor(props) {
    super(props);
    if (this.props.node.nodeContentArea) {
      const contentBlock = htmlToDraft(this.props.node.nodeContentArea);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const neweditorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState: neweditorState,
        score: 0,
        checked: [false],
      };
    } else {
      this.state = {
        editorState: EditorState.createEmpty(),
        score: 0,
        checked: [false],
      };
    }
  }

  handleButtonClicked = () => {
    this.setState({
      buttonClicked: true,
    });
  };

  handleGoBack = () => {
    this.props.removeDecision();
    this.props.history.goBack();
  };

  handleOpenModal = () => this.setState({ open: true });

  handleCloseModal = () => this.setState({ open: false });

  toggleChecked = (i) => async (e) => {
    e.preventDefault();

    let oldChecked = [...this.state.checked];
    let oldScore = this.state.score;
    let newNode = this.props.node;
    let newTotalScore = this.state.score;
    let newScore = "";

    this.state.checked.forEach((check, index) => {
      if (
        index !== i &&
        this.props.node.logicgroups[i] === this.props.node.logicgroups[index] &&
        oldChecked[index] === true
      ) {
        oldChecked[index] = false;
        newScore = parseFloat(this.props.node.logicscores[index]);

        newTotalScore = oldScore - newScore;
      }
    });

    oldChecked[i] = !oldChecked[i];
    await this.setState({
      checked: oldChecked,
      score: newTotalScore,
    });

    if (this.state.checked[i] === true) {
      let oldScore = this.state.score;
      let newScore = parseFloat(newNode.logicscores[i]);
      let newTotalScore = oldScore + newScore;
      await this.setState({
        score: newTotalScore,
      });
    } else {
      let oldScore = this.state.score;
      let newScore = parseFloat(newNode.logicscores[i]);
      let newTotalScore = oldScore - newScore;
      await this.setState({
        score: newTotalScore,
      });
    }
  };

  render() {
    const {
      node,
      tenantId,
      currentPathway,
      decisionSummary,
      pathway,
      typeOfNodePreview,
      updatedNode,
      allowHtml
    } = this.props;

    const { open, editorState } = this.state;
    let html = sanitizeHtml(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
      ,{
        allowedAttributes: {
          'span': ["style"],
        },
        allowedStyles: {
          'span': {
            // Match HEX and RGB
            'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
            'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/]
          }
      }
    });

    const groupnumbers = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];

    return (
      <div>
        <Grid stackable>
          <Grid.Column width={updatedNode === true ? 16 : 8}>
            <Segment.Group onClick={this.handleCloseSideBar}>
              <Segment>
                <h1>
                  <i aria-hidden="true" className="eye ul icon" />
                  {typeOfNodePreview === "newNode" && "New Node"}
                  {typeOfNodePreview === "deletedNode" && "Deleted Node"}
                  {typeOfNodePreview === "Node before Update" &&
                    "Node Before Update"}
                  {typeOfNodePreview === "Node after Update" &&
                    "Node After Update"}
                </h1>
              </Segment>
              <Segment style={center} inverted color="grey">
                <h3>
                  {tenantId} {pathway} Pathway
                </h3>
              </Segment>
              <Segment
                style={center}
                inverted
                color={brandConfig.main.primaryColor}
              >
                <h3>{allowHtml ?  <span dangerouslySetInnerHTML={{__html:node.title}}></span> :  node.title}</h3>
              </Segment>
              {node.image && (
                <Segment textAlign="center">
                  <Modal
                    centered
                    open={open}
                    onClose={this.handleCloseModal}
                    onOpen={this.handleOpenModal}
                    trigger={
                      <div>
                        <p>
                          Click image to enlarge <Icon name="search plus" />
                        </p>
                        <Image style={image} src={node.image} centered />
                      </div>
                    }
                  >
                    <Modal.Header>
                      <Grid>
                        <Grid.Column width={8}>
                          <Image
                            size="small"
                            src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/Orthopathway%20logo%20text%20clipped.png?alt=media&token=32278b9f-929a-4d61-9428-cab101343144"
                            alt="logo"
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Button
                            floated="right"
                            color="grey"
                            onClick={this.handleCloseModal}
                          >
                            X
                          </Button>
                        </Grid.Column>
                      </Grid>{" "}
                    </Modal.Header>
                    <Modal.Content>
                      <Image style={modalImage} src={node.image} centered />
                    </Modal.Content>
                  </Modal>
                </Segment>
              )}
              {node.youtube && (
                <Segment>
                  <Embed id={node.youtube} source="youtube" />
                </Segment>
              )}
              {node.vimeo && (
                <Segment>
                  <Embed id={node.vimeo} source="vimeo" />
                </Segment>
              )}
              {node.nodeContentArea && (
                <Segment>
                  <div>{ReactHtmlParser(html)}</div>
                </Segment>
              )}
              {node.question && (
                <Segment>
                  <p>Question: {allowHtml ?  <span dangerouslySetInnerHTML={{__html:node.question}}></span> :  node.question} </p>
                 

                </Segment>
              )}
              {node.logicfields &&
                node.logicfields[0] !== undefined &&
                node.logicfields[0] !== "" && (
                  <Segment>
                    <Segment inverted color={brandConfig.main.primaryColor}>
                      <b className="label">Select all that apply:</b>
                    </Segment>

                    {groupnumbers.map(
                      (group, indexx) =>
                        node.logicgroups[indexx] !== undefined &&
                        node.logicgroups[indexx] !== "" && (
                          <div key={indexx}>
                            {node.logicgroups[indexx] !== undefined &&
                              node.logicgroups[indexx] !== "" && (
                                <div>
                                  <div>
                                    {node.groupNames &&
                                    node.groupNames[group] ? (
                                      <Header>
                                        {" "}
                                        {allowHtml ?  <span dangerouslySetInnerHTML={{__html:node.node.groupNames[group]}}></span> :  node.groupNames[group]}{" "}
                                      </Header>
                                    ) : null}
                                    {node.logicfields.map(
                                      (logicfield, index) =>
                                        node.logicgroups[index] === group && (
                                          <Button.Group
                                            key={index}
                                            vertical
                                            fluid
                                          >
                                            <Grid columns={2}>
                                              <Grid.Row>
                                                <Grid.Column width={3}>
                                                  <Button
                                                    icon
                                                    color={
                                                      this.state.checked[index]
                                                        ? "green"
                                                        : "red"
                                                    }
                                                    onClick={this.toggleChecked(
                                                      index
                                                    )}
                                                    size="large"
                                                  >
                                                    <Icon
                                                      name={
                                                        this.state.checked[
                                                          index
                                                        ]
                                                          ? "check"
                                                          : "window close"
                                                      }
                                                    />{" "}
                                                  </Button>
                                                </Grid.Column>
                                                <Grid.Column width={13}>
                                                  <Button
                                                    size="large"
                                                    fluid
                                                    onClick={this.toggleChecked(
                                                      index
                                                    )}
                                                    color={
                                                      this.state.checked[index]
                                                        ? brandConfig.main
                                                            .primaryColor
                                                        : null
                                                    }
                                                  >
                                                    {logicfield}
                                                    {node.logicscores[index] !==
                                                      0 &&
                                                      node.logicscores[
                                                        index
                                                      ] !== "0" &&
                                                      node.logicscores[
                                                        index
                                                      ] !== "" &&
                                                      node.showscores && (
                                                        <b>
                                                          {" "}
                                                          +{" "}
                                                          {
                                                            node.logicscores[
                                                              index
                                                            ]
                                                          }
                                                        </b>
                                                      )}
                                                  </Button>
                                                </Grid.Column>
                                              </Grid.Row>
                                            </Grid>
                                          </Button.Group>
                                        )
                                    )}
                                  </div>
                                  {node.logicgroups.some(
                                    (logicgroup) => logicgroup === group
                                  ) && <br></br>}
                                </div>
                              )}
                          </div>
                        )
                    )}

                    {this.state.score !== 0 &&
                      this.state.score !== "0" &&
                      node.showscores && (
                        <Segment inverted color={brandConfig.main.primaryColor}>
                          <b className="label">
                            Total Score: {this.state.score}
                          </b>
                        </Segment>
                      )}
                  </Segment>
                )}

              {node.ruleTypes &&
                node.ruleNames &&
                node.ruleActions &&
                node.ruleOperators[0] !== "" &&
                node.ruleValues && (
                  <Segment>
                    <Button.Group vertical fluid>
                      <div>
                        {node.ruleTypes.map((ruleType, index) => {
                          if (
                            ruleType === "Score" &&
                            node.ruleOperators[index] !== "Contains" &&
                            node.ruleOperators[index] !== "is in range" &&
                            node.ruleOperators[index] !== "" &&
                            node.ruleValues[index] !== ""
                          ) {
                            var number1 = this.state.score;
                            var number2 = parseFloat(node.ruleValues[index]);
                            var operator = node.ruleOperators[index];

                            // eslint-disable-next-line no-eval
                            const result = eval(number1 + operator + number2);
                            if (result === true && node.ruleActions[index]) {
                              return (
                                <Button
                                  animated="fade"
                                  size="huge"
                                  color={brandConfig.main.primaryColor}
                                  key={index}
                                  fluid
                                >
                                  <Button.Content visible>
                                    {allowHtml ?  <span dangerouslySetInnerHTML={{__html:node.ruleNames[index]}}></span> :  node.ruleNames[index]}
                                  </Button.Content>
                                  <Button.Content hidden>
                                  {allowHtml ?  <span dangerouslySetInnerHTML={{__html:node.ruleNames[index]}}></span> :  node.ruleNames[index]}
                                    {" "}
                                    <Icon name="arrow alternate circle right" />
                                  </Button.Content>
                                </Button>
                              );
                            }
                          }
                          return null;
                        })}
                        {node.ruleTypes.map((ruleType, index) => {
                          if (
                            ruleType === "Score" &&
                            node.ruleOperators[index] !== "Contains" &&
                            node.ruleOperators[index] === "is in range" &&
                            node.ruleOperators[index] !== "" &&
                            node.ruleValuesMin[index] !== "" &&
                            node.ruleValuesMax[index] !== ""
                          ) {
                            var number1 = this.state.score;
                            var number2 = parseFloat(node.ruleValuesMin[index]);
                            var number3 = parseFloat(node.ruleValuesMax[index]);
                            if (
                              number1 >= number2 &&
                              number1 <= number3 &&
                              node.ruleActions[index]
                            ) {
                              return (
                                <Button
                                  animated="fade"
                                  size="huge"
                                  color={brandConfig.main.primaryColor}
                                  key={index}
                                  fluid
                                >
                                  <Button.Content visible>
                                    {node.ruleNames[index]}
                                  </Button.Content>
                                  <Button.Content hidden>
                                    {node.ruleNames[index]}{" "}
                                    <Icon name="arrow alternate circle right" />
                                  </Button.Content>
                                </Button>
                              );
                            }
                          }
                          return null;
                        })}
                        {node.ruleTypes.map(
                          (ruleType, index) =>
                            ruleType === "Field toggled" &&
                            node.ruleOperators[index] === "Contains" &&
                            node.logicfields.map((logicfield, indexx) => {
                              let contains = logicfield.includes(
                                node.ruleValues[index]
                              );
                              if (
                                contains === true &&
                                this.state.checked[indexx] === true &&
                                node.ruleActions[index]
                              ) {
                                return (
                                  <Button
                                    animated="fade"
                                    size="huge"
                                    color={brandConfig.main.primaryColor}
                                    key={indexx}
                                    fluid
                                  >
                                    <Button.Content visible>
                                      {node.ruleNames[index]}
                                    </Button.Content>
                                    <Button.Content hidden>
                                      {node.ruleNames[index]}{" "}
                                      <Icon name="arrow alternate circle right" />
                                    </Button.Content>
                                  </Button>
                                );
                              }
                              return null;
                            })
                        )}
                      </div>
                    </Button.Group>
                  </Segment>
                )}
              {node.answers && (
                <Segment>
                  <Button.Group vertical fluid>
                    {node.answers &&
                      node.actions &&
                      node.answers.map((answer, index) => (
                        <span key={index}>
                          {node.actions[index] !== "I will connect later" &&
                            node.actions[index] && (
                              <div key={answer}>
                                <Button
                                  animated="fade"
                                  compact
                                  size="huge"
                                  color={brandConfig.main.primaryColor}
                                  floated="right"
                                >
                                  <Button.Content visible>
                                    {allowHtml ?  <span dangerouslySetInnerHTML={{__html:answer}}></span> :  answer} 

                                  </Button.Content>
                                  <Button.Content hidden>
                                      {allowHtml ?  <span dangerouslySetInnerHTML={{__html:answer}}></span> :  answer} {" "}
                                    <Icon name="arrow alternate circle right" />
                                  </Button.Content>
                                </Button>
                              </div>
                            )}
                        </span>
                      ))}
                  </Button.Group>
                  <Button fluid animated color="grey">
                    <Button.Content visible>
                      Go back to previous Node
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="arrow alternate circle left" />
                      Go back to previous Node
                    </Button.Content>
                  </Button>
                  {node.print && (
                    <Modal
                      trigger={
                        <Button fluid color="green">
                          <Icon name="print" />
                          Print Preview
                        </Button>
                      }
                      closeIcon
                    >
                      <Modal.Header>Print Preview</Modal.Header>
                      <Modal.Content>
                        <div>
                          <ReactToPrint
                            trigger={() => (
                              <Button fluid color="green">
                                <Icon name="print" />
                                Print this out!
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
                          <ComponentToPrint
                            decisionSummary={decisionSummary}
                            tenantId={tenantId}
                            currentPathway={currentPathway}
                            node={node}
                            editorState={editorState}
                            ref={(el) => (this.componentRef = el)}
                          />
                        </div>
                      </Modal.Content>
                    </Modal>
                  )}
                </Segment>
              )}

              {node.files && node.files[0] && (
                <Segment>
                  <List>
                    {node.files &&
                      node.files.map(
                        (file, index) =>
                          node.fileNames[index] !== " " && (
                            <List.Item key={index}>
                              <a
                                href={file}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                {node.fileNames[index]}
                              </a>
                            </List.Item>
                          )
                      )}
                  </List>
                </Segment>
              )}
            </Segment.Group>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(null, actions)(PathwayRecentActivityNodePreview)
);
