import React, { useEffect, useState } from "react";
import {
  Button,
  Popup,
  Icon,
  Header,
  Modal,
  Dropdown,
  Image,
  Input,
  Form,
  Checkbox,
  Segment,
  Message,
} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { arrayGroups } from "../../../app/common/util/ImageGroupSelector";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

export default function UploadImg({
  galleryElement,
  type,
  isSuperAdmin,
  pullData,
  handleAddImage,
  cropperRef,
  // todo Check
   addImgToGallery
}) {
  const [openModal, setOpenModal] = useState(false);
  const [addImage, setAddImage] = useState(true);
  const [imgGroups, setImgGroups] = useState([]);
  const [imgToAdd, setImgToAdd] = useState();
  const [imgName, setImgName] = useState("");
  const [isImgChange, setIsImgChange] = useState(false);
  const [isImgHidden, setIsImgHidden] = useState(false);
  const [croppedImg, setCroppedImg] = useState();
  const [formError, setFormError] = useState(false);


  useEffect(() => {
    if (type === "update") {
      setAddImage(false);
      setImgName(galleryElement.imageName);
      setImgGroups(Object.keys(galleryElement.groups));
      setImgToAdd(galleryElement.imageURL);
      setIsImgHidden(galleryElement.isHidden);
    }
  }, [galleryElement, type]);

  const handleOpenModal = () => {
    if (type === "upload") {
      setAddImage(true);
      setImgToAdd();
      setImgName("");
      setImgGroups([])
    }
    setOpenModal(!openModal);
    setFormError(false);
  };
  const onDrop = (file) => {
    setImgToAdd(file[0]);
    setAddImage(false);
  };
  const cropImage = () => {
    if (typeof cropperRef.current.getCroppedCanvas() === "undefined") {
      return;
    }
    cropperRef.current.getCroppedCanvas().toBlob((blob) => {
      const myFile = new File([blob], `${imgToAdd.name}`, {
        type: blob.type,
      });
      setCroppedImg(myFile);
    }, `${imgToAdd.type}`);
  };

  const uploadImgFile = async () => {
    if (imgToAdd && imgGroups.length > 0 && imgName !== "") {
      setFormError(false);
      if (isImgChange || type === "upload") {
        const randomId = uuidv4();
        const fileExtension = imgToAdd.name.split(".").pop();
        croppedImg.id = `${randomId}.${fileExtension}`;
        const fileRef = firebase
          .storage()
          .ref(`/OPGallery/gallery/${croppedImg.id}`);
        await fileRef.put(croppedImg);
      }
      uploadImgInfo();
      handleOpenModal();
    } else {
      setFormError(true);
    }
  };
  const uploadImgInfo = async () => {

    const groups = imgGroups.reduce((obj, item) => {
      return { ...obj, [item]: true };
    }, {});
    
    let linkImg;
    if (!isImgChange && type === "update") {
      linkImg = galleryElement.imageURL;
    } else {
      const imgRef = firebase
        .storage()
        .ref(`/OPGallery/gallery/${croppedImg.id}`);
      linkImg = await imgRef.getDownloadURL();
    }

    const ImageObject = {
      groups,
      imageName: imgName,
      imageURL: linkImg,
      isHidden: isImgHidden || false,
      imageUploadTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // todo Check
    // addImgToGallery(ImageObject);
    if (type === "update") {
      const db = firebase
        .firestore()
        .collection("gallery")
        .doc(galleryElement.id);
      await db.update(ImageObject);
    } else {
      const db = firebase.firestore().collection("gallery");
      const docRef = await db.add(ImageObject);
      ImageObject["id"]= docRef.id
      addImgToGallery && addImgToGallery(ImageObject);
    }
  };

  const deleteImg = () => {
    if (type === "update") {
      setIsImgChange(true);
    }
    setImgToAdd();
    setAddImage(true);
  };

  const getImgToInsert = () => {
    if (imgToAdd) {
      pullData(imgToAdd);
    } else {
      pullData(galleryElement.imageURL);
    }
  };

  return (
    <>
      {isSuperAdmin ? (
        <>

          {type === "upload" ? (
            <Popup
              trigger={
                <Button color="green" onClick={handleOpenModal}>
                  <Icon name="image" /> Upload a new Image
                </Button>
              }
              content="Click here to add an image. You must hold the copyright for the image as a copy will be made on the database. Any image dropped into the editor will be saved on the database on submitting the node."
              wide
            />
          ) : (
            
            <Button attached="bottom" color="teal" onClick={handleOpenModal}>
              Update Image
            </Button>
          )}
          <Modal open={openModal}>
            <Segment>
              <Form error={formError}>
                {formError && (
                  <Message
                    error={formError}
                    header="Action Forbidden"
                    content={`You can NOT ${type} an Image with empty fiels.`}
                  />
                )}
                <Segment textAlign="center" vertical>
                  <Form.Field>
                    <label>Image to Add</label>
                    
                    {addImage ? (
                      <center>
                        <Dropzone
                          accept="image/*"
                          onDrop={onDrop}
                          multiple={false}
                        >
                          <div
                            style={{ paddingTop: "30px", textAlign: "center" }}
                          >
                            <Icon name="upload" size="huge" />
                            <Header content="Drop image here or click to add" />
                          </div>
                        </Dropzone>
                      </center>
                    ) : (
                      <>
                        {type === "upload" || isImgChange ? (
                          <Cropper
                            style={{ height: 200, width: "50%" }}
                            ref={cropperRef}
                            src={imgToAdd.preview}
                            viewMode={1}
                            dragMode="none"
                            guides={true}
                            scalable={true}
                            cropBoxMovable={true}
                            cropBoxResizable={true}
                            crop={cropImage}
                            background={true}
                            autocrop={true}
                            autoCropArea={1}
                            modal={false}
                            highlight={false}
                            rotatable={true}
                          />
                        ) : (
                          <Image
                            src={galleryElement.imageURL}
                            size="medium"
                            wrapped
                          />
                        )}
                      </>
                    )}
                  </Form.Field>
                </Segment>
                <Segment vertical>
                  <Form.Field>
                    <label>Manage Image</label>
                    {!addImage && (
                      <>
                        <Button
                          content="Delete Image"
                          negative
                          onClick={deleteImg}
                        />
                      </>
                    )}
                    <Checkbox
                      label="Hide Image"
                      onClick={() => setIsImgHidden(!isImgHidden)}
                      checked={isImgHidden}
                      size="small"
                    />
                  </Form.Field>
                </Segment>
                <Segment vertical>
                  <Form.Field>
                    <label>Choose Image Name</label>
                    <Input
                      onChange={(e) => setImgName(e.target.value)}
                      placeholder={imgName}
                    ></Input>
                  </Form.Field>
                </Segment>
                <Segment vertical>
                  <Form.Field>
                    <label>Select an Image group</label>
                    <Dropdown
                      placeholder="Groups"
                      multiple
                      selection
                      options={arrayGroups}
                      value={imgGroups}
                      onChange={(e, { value }) => setImgGroups(value)}
                    />
                  </Form.Field>
                </Segment>
                {type === "update" ? (
                  <Segment vertical>
                    <Modal.Actions>
                      <Button
                        content="Update Image"
                        positive
                        onClick={uploadImgFile}
                      />
                      <Button
                        content="Close Update Image"
                        negative
                        onClick={handleOpenModal}
                      />
                    </Modal.Actions>
                  </Segment>
                ) : (
                  <>
                    <Segment vertical>
                      <Modal.Actions>
                        <Button
                          content="Upload Image"
                          positive
                          onClick={uploadImgFile}
                        />
                        <Button
                          content="Close Add Image"
                          negative
                          onClick={handleOpenModal}
                        />
                      </Modal.Actions>
                    </Segment>
                  </>
                )}
              </Form>
            </Segment>
          </Modal>
        </>
      ) : (
        <>
          {type === "upload" ? (
            <Popup
              trigger={
                <Button color="green" onClick={handleAddImage}>
                  <Icon name="image" /> Upload a new Image
                </Button>
              }
              content="Click here to add an image. You must hold the copyright for the image as a copy will be made on the database. Any image dropped into the editor will be saved on the database on submitting the node."
              wide
            />
          ) : (
            <Button
              attached="bottom"
              color="grey"
              onClick={() => getImgToInsert()}
            >
              Insert Image
            </Button>
          )}
        </>
      )}
    </>
  );
}
