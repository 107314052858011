import React, { useState, useEffect } from "react";
import {
  Grid,
  GridRow,
  GridColumn,
  Checkbox,
  Button,
  Modal,
  Icon,
  Image,
  Segment,
  Message
} from "semantic-ui-react";
import UploadImg from "./UploadImg";
import firebase from "firebase";
import ImgComponent from "./ImgComponent";
import ImageGroupSelector from "../../../app/common/util/ImageGroupSelector";

export default function GalleryComponent(props) {
  const [filterCriteria, setFilterCriteria] = useState("");
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showHiddenImages, setShowHiddenImages] = useState(false);
const [loading, setLoading]=useState(false)
  let cropperRef = React.createRef();

  const loadImageForEditing = (data) => {
    props.handleSelectImageFromGallery(data);
    handleModalOpen();
  };

  useEffect(() => {
    
   
    const getImages = async () => {

      if(!filterCriteria){
        return;
      }
      setLoading(true)
        let  gallerySnapshot;
        gallerySnapshot = firebase.firestore().collection("gallery");

      if (showHiddenImages) {
          gallerySnapshot = gallerySnapshot.where("isHidden", "==", true);
      }

      if(filterCriteria && filterCriteria !== "allImages") {
        gallerySnapshot = gallerySnapshot.where(`groups.${filterCriteria}`, "==", true);
      }

      const data = await gallerySnapshot.get();
      const galleryItems = [];
      data.forEach((doc) => {
        const data = {
          ...doc.data(),
          id: doc.id,
        };
        galleryItems.push(data);
      });
      setFilteredGallery(galleryItems);
      setLoading(false)
    };
    
    getImages();
    
  }, [filterCriteria, showHiddenImages]);

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const importImageButtonText = props.importButtonText || "Import an image from Gallery"
  // todo

   const addImgToGallery = (imgObj) => {
     filteredGallery.unshift(imgObj);
     setFilteredGallery([...filteredGallery]);
   };

  return (
    <>
      {!props.isSuperAdmin ? (
        <>
          <Button.Group fluid style={{ flex: "1 1 auto" }}>
            <Modal
              size={"large"}
              centered
              dimmer={"blurring"}
              open={modalOpen}
              onClose={handleModalOpen}
              onOpen={handleModalOpen}
              trigger={
                <Button color="teal" onClick={(e) => e.preventDefault()}>
                  <Icon name="image" /> {importImageButtonText}
                </Button>
              }
            >
              <Modal.Header>
                <Grid>
                  <Grid.Column width={3}>
                    <Image size="small" src="/assets/logo.png" alt="logo" />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <center>Image Gallery</center>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Button
                      floated="right"
                      color="grey"
                      onClick={handleModalOpen}
                    >
                      X
                    </Button>
                  </Grid.Column>
                </Grid>{" "}
              </Modal.Header>
              <Modal.Content>
                <Grid>
                  <GridRow centered columns={1} verticalAlign="middle">
                    <GridColumn>
                      <p>Select an Image group</p>
                      <ImageGroupSelector
                        onImageGroupChange={setFilterCriteria}
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow columns={3}>
                    {filteredGallery.map((galleryElement) => {
                      
                      return (
                        <ImgComponent
                          galleryElement={galleryElement}
                          key={galleryElement.id}
                          pullData={loadImageForEditing }
                        />
                      );
                    })}
                  </GridRow>
                </Grid>
              </Modal.Content>
            </Modal>
            {!props.disableCustomImages && <UploadImg type="upload" handleAddImage={props.handleAddImage} />}
          </Button.Group>
        </>
      ) : (
        <>
          <Segment>
            <Grid>
              <GridRow centered columns={3} verticalAlign="bottom">
                <GridColumn verticalAlign="middle">
                  <p>Select an image group</p>
                  <ImageGroupSelector onImageGroupChange={setFilterCriteria} />
                </GridColumn>
                <GridColumn verticalAlign="middle">
                  
                  <UploadImg
                    type="upload"
                    isSuperAdmin={props.isSuperAdmin}
                    cropperRef={cropperRef}
                    addImgToGallery={addImgToGallery}
            
                  />
                </GridColumn>
                <GridColumn verticalAlign="middle">
                  <Checkbox
                    size="small"
                    label="Show Hidden Images"
                    checked={showHiddenImages}
                    onClick={() => setShowHiddenImages(!showHiddenImages)}
                  />
                </GridColumn>
              </GridRow>
              <GridRow centered>
             
                {filterCriteria && filteredGallery.map((galleryElement) => {
                  return (
                    <ImgComponent
                      galleryElement={galleryElement}
                      key={galleryElement.id}
                      isSuperAdmin={props.isSuperAdmin}
                      cropperRef={cropperRef}
                      addImgToGallery={addImgToGallery}
                    />
                  );
                })}

                {!filterCriteria && 
                  <Message info icon style={{ width: "98%" }}><Icon name='sync' loading={loading}  />Please select an image group to start</Message>
              

                }
              </GridRow>
            </Grid>
          </Segment>
        </>
      )}
    </>
  );
}
