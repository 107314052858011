import GalleryComponent from "features/superAdmin/manageGallery/GalleryComponent";
import { useEffect } from "react";
import { Grid, Image } from "semantic-ui-react"
import { IFindAndReplaceInput } from "./types"


const ImageReplace = ({selectedTenant,setSearchTerm,setReplaceTerm, searchTerm, replaceTerm, setCaseSensitive,setFields } : IFindAndReplaceInput) => {


    useEffect(() => {
        setCaseSensitive(false)
        setFields(["image"])

    }, [setCaseSensitive, setFields])

    return (
        <Grid.Row>
            <Grid.Column>
                {!Boolean(searchTerm) && <GalleryComponent importButtonText="Select image to find" disableCustomImages handleSelectImageFromGallery={(item : any) => setSearchTerm(item)} />}
                {Boolean(searchTerm) && <Image src={searchTerm}  />}
            </Grid.Column>
            <Grid.Column>
                {!Boolean(replaceTerm) && <GalleryComponent importButtonText="Select image to replace with" disableCustomImages handleSelectImageFromGallery={(item : any) => setReplaceTerm(item)} />}
                {Boolean(replaceTerm) && <Image src={replaceTerm}  />}
            </Grid.Column>
        </Grid.Row>
    )


}

export default ImageReplace