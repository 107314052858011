import { HighLightType, IFileReplace, IHightLightResult } from "./types";
import firebase from "firebase";
const isObject = (obj: any) => {
  var type = typeof obj;
  return (type === "function" || type === "object") && !!obj;
};

function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const highlightSearchTerm = (
  searchTerm: string,
  content: string,
  replaceTerm?: string
): IHightLightResult => {
  if (!Boolean(content)) {
    return {
      combined: content,
      searchOnly: content,
      replaceOnly: content,
      matchFound: false,
    };
  }
  var regEx = new RegExp(escapeRegExp(searchTerm), "ig");
  let highlightReplaceText = "";
  highlightReplaceText = `<span style="background-color:#0f0;color:#000;"  class="search-highlight-2">${replaceTerm}</span>`;

  let highlightSearchTerm = "";

  const highlightStyle = replaceTerm
    ? "background-color:#f00;color:#fff;text-decoration:line-through"
    : "background-color:#ff0";
  highlightSearchTerm = `<span style="${highlightStyle}" class="search-highlight-2">$&</span>`;

  const matchFound = content.match(regEx) != null;

  var combinedReplace = `${highlightSearchTerm}${highlightReplaceText}`;

  return {
    combined: content.replace(regEx, combinedReplace),
    searchOnly: content.replace(regEx, highlightSearchTerm),
    replaceOnly: content.replace(regEx, highlightReplaceText),
    rawReplace: replaceTerm ? content.replace(regEx, replaceTerm) : undefined,
    matchFound,
  };
};

export const extractUpdateObject= ( 
  nodeObject: any, 
  replaceTerm: string | undefined | IFileReplace,
  fieldsToIncudes: string[], updatingUser:string) => {
 //Once the replace function has run we only want to update the fields specified, so we extract them

    //Special handling for file updates
    if(isObject(replaceTerm)){  
      return {
        fileNames: nodeObject["fileNames"],
        files:nodeObject["files"],
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: updatingUser
      }

    }else{
      return fieldsToIncudes.map(field => {
        return Boolean(nodeObject[field]) ? { [field] : nodeObject[field]} : {}
      }).reduce((obj, field) => Object.assign(obj,field), {
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: updatingUser
      } )
      
  }
}

export const replaceInObject = (
  nodeObject: any,
  searchTerm: string,
  replaceTerm: string | undefined | IFileReplace,
  fieldsToIncudes: string[],
  displayType: HighLightType
) => {

  if(isObject(replaceTerm)){
   
    const fileReplace = (replaceTerm as IFileReplace);
    if(Array.isArray(nodeObject.fileNames)){
        for(let i=0; i<nodeObject.fileNames.length;i++){
          if(nodeObject.files[i] === searchTerm){
            nodeObject.fileNames[i] = fileReplace.fileName;
            nodeObject.files[i] = fileReplace.filePath

          }
        }
    }

    return
  }

  const objectProperties = Object.keys(nodeObject);
  for (let i = 0; i < objectProperties.length; i++) {
    const property = objectProperties[i];
    //If it is an integer then we are in an array of a property we do want to search
    if (!fieldsToIncudes.includes(property) && isNaN(+property)) {
      
      continue;
    }

 
    if (isObject(nodeObject[property])) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      replaceInObject(
        nodeObject[property],
        searchTerm,
        replaceTerm,
        fieldsToIncudes,
        displayType
      );
      continue;
    }
   

    if (typeof nodeObject[property] === "string" && typeof replaceTerm === "string") {
    
      const result = highlightSearchTerm(
        searchTerm,
        nodeObject[property],
        replaceTerm
      );
       
      if (result.matchFound) {
        // nodeObject.matchFound = [...nodeObject.matchFound,property]
        switch (displayType) {
          case HighLightType.Search:
            nodeObject[property] = result.searchOnly;
            break;
          case HighLightType.Replace:
            nodeObject[property] = result.replaceOnly;
            break;
          case HighLightType.Combined:
            nodeObject[property] = result.combined;
            break;
          case HighLightType.None:
            //If we are just doing a find without a replace then we just return the original value
            nodeObject[property] = result.rawReplace || nodeObject[property];
        }
      }
    }
  }
};
