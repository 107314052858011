import { useEffect, useState } from "react"
import { Dropdown, DropdownItemProps, Grid } from "semantic-ui-react"
import firebase from "../../../app/config/firebase"
import { IFileReplace, IFindAndReplaceInput } from "./types"

const db = firebase.firestore();

const FileReplace = ({ selectedTenant, setSearchTerm, setReplaceTerm, setCaseSensitive, setFields }: IFindAndReplaceInput) => {

    const [fileList, setFileList] = useState<DropdownItemProps[]>([])
  
    useEffect(() => {
        setCaseSensitive(false)
        setFields(["files"])
    }, [setCaseSensitive,setFields])


    useEffect(() => {
        const load = async () => {
            
            const dropdownOptions: Array<DropdownItemProps> = []
            const imageRefs = await db.collection(`${selectedTenant}/Files/files`).get();
            imageRefs.docs.forEach((x,i) => {
                const fileData = x.data();
                const file = { "key":  i,"text": fileData.fileName, "value": fileData.file }
                dropdownOptions.push(file)
            })
            setFileList(dropdownOptions)
            
        }

        load()

    }, [selectedTenant, setFileList])

    return (
        <>
            {Boolean(fileList.length) && <Grid.Row>
                <Grid.Column>
                    <Dropdown selection placeholder="Find file" fluid options={fileList} onChange={(e, { value }) => setSearchTerm(value as string)} ></Dropdown>
                </Grid.Column>
                <Grid.Column>
                    <Dropdown selection placeholder="Replace with file" fluid options={fileList} onChange={(e, {value}) => { 
                        const replaceItem = fileList.find(x=>x.value === value); 
                        console.log(replaceItem)
                        setReplaceTerm({ fileName: replaceItem!.text, filePath: replaceItem!.value} as IFileReplace)
                        }} 
                        ></Dropdown>
                </Grid.Column>
            </Grid.Row>}

        </>
    )

}

export default FileReplace