//Copyright 2018 Nathan Moore

import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const LoadingComponent = (inverted) => {
  return (
    <Dimmer inverted={true} active={true}>
      <Loader />
    </Dimmer>
  );
};

export default LoadingComponent;
