//Copyright 2018 Nathan Moore

import { SET_SIDEBAR, CLOSE_SIDEBAR } from "./setSideBarConstants";
import { createReducer } from "../../../app/common/util/reducerUtil";

const initialState = {
  sideBar: "false",
};

export const setSideBar = (state, payload) => {
  const newSideBar = payload;
  return { setSideBar: newSideBar };
};

export const closeSideBar = (state, payload) => {
  return { closeSideBar: payload };
};

export default createReducer(initialState, {
  [SET_SIDEBAR]: setSideBar,
  [CLOSE_SIDEBAR]: closeSideBar,
});
