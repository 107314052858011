//Copyright 2018 Nathan Moore

import { SET_EDITOR } from "./editPathwayConstants";
import { createReducer } from "../../../app/common/util/reducerUtil";

const initialState = false;

export const setEditor = (state, payload) => {
  const bool = payload;
  return bool;
};

export default createReducer(initialState, {
  [SET_EDITOR]: setEditor,
});
