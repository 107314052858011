//Copyright 2018 Nathan Moore

import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_NODE,
  DELETE_NODE,
  UPDATE_NODE,
  FETCH_NODES,
} from "./nodeConstants";

const initialState = [];

export const createNode = (state, payload) => {
  return [...state, Object.assign({}, payload.node)];
};

export const updateNode = (state, payload) => {
  return [
    ...state.filter((node) => node.id !== payload.node.id),
    Object.assign({}, payload.node),
  ];
};

export const deleteNode = (state, payload) => {
  return [...state.filter((node) => node.id !== payload.nodeId)];
};

export const fetchNodes = (state, payload) => {
  return payload.nodes;
};

export default createReducer(initialState, {
  [CREATE_NODE]: createNode,
  [UPDATE_NODE]: updateNode,
  [DELETE_NODE]: deleteNode,
  [FETCH_NODES]: fetchNodes,
});
