//Copyright 2018 Nathan Moore

import { SET_EDITOR } from "./editPathwayConstants";

export const setEditor = (bool) => {
  return {
    type: SET_EDITOR,
    payload: bool,
  };
};
