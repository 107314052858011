//Copyright 2018 Nathan Moore

import {
  SIGN_IN_USER,
  SIGN_OUT_USER,
  MFA_REQUIRED,
  SIGN_IN_ERROR,
  FORGOTTEN_PASSWORD,
  MFA_REGISTATION_ATTEMPT,
  SIGN_IN_STATE_IDLE,
  SIGN_IN_STATE_ERROR,
  SIGN_IN_STATE_COMPLETE,
  SIGN_IN_STATE_MFA_REQUIRED,
  MFA_UPDATE,
} from "./authConstants";
import { createReducer } from "../../app/common/util/reducerUtil";

const initialState = {
  loginProcessState: SIGN_IN_STATE_IDLE,
  error: null,
};

export const MFARegistrationAtempt = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

export const MFARequired = (state, payload) => {
  return {
    ...state,
    ...payload,
    loginProcessState: SIGN_IN_STATE_MFA_REQUIRED,
    UserCredentialValid: true,
  };
};

export const MFAUpdate = (state) => {
  return {
    ...state,
    MFAUpdate: true,
  };
};

export const SignInError = (state, payload) => {
  return {
    ...state,
    ...payload,
    loginProcessState: SIGN_IN_STATE_ERROR,
  };
};

export const ForgottenPassword = (state) => {
  return {
    ...state,
    forgottenpassword: true,
  };
};

export const SignOut = (state, payload) => {
  return { ...initialState };
};

export const SetLoginIdle = (state, payload) => {
  return {
    ...initialState,
    loginProcessState: SIGN_IN_STATE_IDLE,
  };
};

export const SignInUser = (state, payload) => {
  //On signin we need to clear out the state object to remove anything used during the authentication process (e.g. MFA stuff)
  return {
    ...initialState,
    ...payload,
    loginProcessState: SIGN_IN_STATE_COMPLETE,
  };
};

export default createReducer(initialState, {
  [SIGN_IN_USER]: SignInUser,
  [SIGN_OUT_USER]: SignOut,
  [MFA_REQUIRED]: MFARequired,
  [SIGN_IN_ERROR]: SignInError,
  [FORGOTTEN_PASSWORD]: ForgottenPassword,
  [SIGN_IN_STATE_IDLE]: SetLoginIdle,
  [MFA_REGISTATION_ATTEMPT]: MFARegistrationAtempt,
  [MFA_UPDATE]: MFAUpdate,
});
