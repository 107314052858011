// Vendor
import { FC, useEffect, lazy } from "react";
import { Container } from "semantic-ui-react";
import Loadable from "react-loadable";
import { Route, Switch, useHistory } from "react-router-dom";

// App
import brandConfig from "../../brandConfig.json";
import LoadingComponent from "../../app/layout/LoadingComponent";
import HomePage from "../../features/home";
import useServiceWorkerUpdate from "../data/useServiceWorkerUpdate";
import FireStoreContainer from "../firestore/FireStoreContainer";
import { getDefaultTenantId, IProfile } from "../common/access";
import { toastr } from "react-redux-toastr";
import FindAndReplacePage from "../../features/pathwaybuilder/FindAndReplace/FindReplacePage";
import GalleryComponent from "../../features/superAdmin/manageGallery/GalleryComponent";

const ServiceDeskCreateIssue = lazy(() => import("../common/issues/createIssue"))

const makeLoadable = (loader: any, props?: any) =>
  Loadable({
    loader,
    loading: LoadingComponent,
    render(loaded: any) {
      const Component = loaded.default;
      return <Component {...props} />;
    },
  });

const AsyncHomePage = makeLoadable(() => import("../../features/home"));
const AsyncTutorial = makeLoadable(() => import("../../features/Tutorials"));
const AsyncNavBar = makeLoadable(() => import("../../features/nav"));
const AsyncSettingsDashboard = makeLoadable(
  () => import("../../features/user/Settings")
);
const AsyncUserDetailedPage = makeLoadable(
  () => import("../../features/user/UserDetailed/")
);
const AsyncPathwayRecentActivityPage = makeLoadable(
  () => import("../../features/pathwaybuilder/PathwayRecentActivity/")
);

const AsyncPathwayBuilderStartPage = makeLoadable(
  () => import("../../features/pathwaybuilder")
);
const AsyncNodePreviewContainer = makeLoadable(
  () => import("../../features/pathwaybuilder/NodePreview")
);
const AsyncNodePreviewStagingContainer = makeLoadable(
  () =>
    import(
      "../../features/pathwaybuilder/NodePreview/NodePreviewStagingContainer"
    )
);
const AsyncNodeForm = makeLoadable(
  () => import("../../features/pathwaybuilder/NodeForm")
);
const AsyncModalManager = makeLoadable(() => import("../../features/modals"));

const AsyncImportContentPage = makeLoadable(
  () => import("../../features/importing/ImportNodeContainer")
);
const AsyncDashboard = makeLoadable(() => import("../../features/dashboard"));
const AsyncActivateAccount = makeLoadable(
  () => import("../../features/auth/Login/ActivateAccount"),
  { title: "Activate Your Account" }
);
const AsyncResetPassword = makeLoadable(
  () => import("../../features/auth/Login/ActivateAccount"),
  { title: "Reset Your Password" }
);
const AsyncUserManagement = makeLoadable(
  () => import("../../features/userManagement/")
);
const AsyncDesigner = makeLoadable(
  () => import("../../features/pathwaybuilder/Designer")
);
const AsyncSuperAdminTools = makeLoadable(
  () => import("../../features/superAdmin/")
);
// const AsyncManageGallery = makeLoadable(
//   () => import("../../features/superAdmin/manageGallery/GalleryComponent")
// );

const AsyncIssueLog = makeLoadable(() => import("../../features/issues"));
// const AsyncReferenceNodesPage = makeLoadable(
//   () => import("../../features/ReferenceNodes")
// );

// Pathways
const OPAsyncTraumaPathway = Loadable({
  loader: () =>
    import(
      "../../features/OPtraumapathway/TraumaPathwayContainer/TraumaPathwayContainer"
    ),
  loading: LoadingComponent,
});

const OPAsyncElectivePathways = Loadable({
  loader: () =>
    import(
      "../../features/OPelectivepathways/electivePathwaysContainer/ElectivePathwaysContainer"
    ),
  loading: LoadingComponent,
});

const ENTAsyncAcutePathway = Loadable({
  loader: () =>
    import(
      "../../features/ENTacutepathway/TraumaPathwayContainer/TraumaPathwayContainer"
    ),
  loading: LoadingComponent,
});

const ENTAsyncElectivePathways = Loadable({
  loader: () =>
    import(
      "../../features/ENTelectivepathways/electivePathwaysContainer/ElectivePathwaysContainer"
    ),
  loading: LoadingComponent,
});

const doesPathwayGroupExist = (groupName: string) =>
  brandConfig.pathways.groups.find((group: any) => group.id === groupName);

const getPathwayGroupUrl = (groupName: string) =>
  brandConfig.pathways.groups.find((group: any) => group.id === groupName)?.url;

interface IAppInnerProps {
  profile: IProfile;
}

const AppInner: FC<IAppInnerProps> = ({ profile }) => {
  useServiceWorkerUpdate();
  const history = useHistory();

  useEffect(() => {
    if (profile.isLoaded && !profile.uid) {
      if (
        history.location.pathname !== "/" &&
        history.location.pathname !== "/activate-account" &&
        history.location.pathname !== "/reset-password"
      ) {
        history.push("/");
      }
    }
  }, [profile, history]);

  if (profile.isLoaded && profile.uid && !profile.hasActivatedAccount) {
    return (
      <Container className="main">
        <AsyncActivateAccount />
      </Container>
    );
  }

  if (!profile.isLoaded) return <LoadingComponent />;

  return (
    <div>
      <AsyncModalManager />
      <Container className="main">
        <Switch>
          <Route exact path="/" component={AsyncHomePage} />
        </Switch>
      </Container>

      <Route
        path="/(.+)"
        render={() => {
          if (
            profile.isLoaded &&
            profile.uid &&
            !getDefaultTenantId(profile) &&
            history.location.pathname !== "/"
          ) {
            toastr.error(
              "No Access",
              "It looks like you don't have access to any Trusts. Please contact your Chief Editor to reinvite you."
            );
            history.push("/");
            return;
          }
          return (
            <div>
              <AsyncNavBar /> <br />
              <Container className="main superMainContainer">
                <Switch>
                  <Route exact path="/homepage" component={AsyncHomePage} />

                  {/* Dynamic Pathway Routes */}
                  {doesPathwayGroupExist("OPtrauma") && (
                    <Route
                      path={getPathwayGroupUrl("OPtrauma")}
                      component={OPAsyncTraumaPathway}
                    />
                  )}
                  {doesPathwayGroupExist("OPelective") && (
                    <Route
                      path={getPathwayGroupUrl("OPelective")}
                      component={OPAsyncElectivePathways}
                    />
                  )}
                  {doesPathwayGroupExist("ENTacute") && (
                    <Route
                      path={getPathwayGroupUrl("ENTacute")}
                      component={ENTAsyncAcutePathway}
                    />
                  )}
                  {doesPathwayGroupExist("ENTelective") && (
                    <Route
                      path={getPathwayGroupUrl("ENTelective")}
                      component={ENTAsyncElectivePathways}
                    />
                  )}
                  <Route
                    path="/pathwayRecentActivity"
                    component={AsyncPathwayRecentActivityPage}
                  />
                  <Route
                    path="/pathwaybuilderstartpage"
                    component={AsyncPathwayBuilderStartPage}
                  />
                  <Route path="/designer" component={AsyncDesigner} />
                  <Route path="/dashboard" component={AsyncDashboard} />
                  <Route path="/manage-users" component={AsyncUserManagement} />
                  <Route path="/issueLog" component={AsyncIssueLog} />
                  <Route
                    path="/profile/:id"
                    component={AsyncUserDetailedPage}
                  />
                  <Route path="/settings" component={AsyncSettingsDashboard} />
                  <Route
                    path="/node/:id"
                    component={AsyncNodePreviewContainer}
                  />
                  <Route
                    path="/activate-account"
                    component={AsyncActivateAccount}
                  />
                  <Route
                    path="/reset-password"
                    component={AsyncResetPassword}
                  />
                  <Route
                    path="/stagenode/:id"
                    component={AsyncNodePreviewStagingContainer}
                  />
                  <Route path="/import" component={AsyncImportContentPage} />
                  <Route
                    path="/nextnode/:title"
                    component={AsyncNodePreviewContainer}
                  />
                  <Route
                    path="/nextnode/I will connect later"
                    component={AsyncUserDetailedPage}
                  />
                  <Route path="/tutorials" component={AsyncTutorial} />
                  <Route
                    path="/superadmintools"
                    component={AsyncSuperAdminTools}
                  />
                  <Route path="/manage/:id" component={AsyncNodeForm} />
                  <Route path="/stagemanage/:id" component={AsyncNodeForm} />
                  <Route path="/createNode" component={AsyncNodeForm} />                 
                  <Route path="/find-and-replace" component={FindAndReplacePage} />
                  <Route path="/servicedesk" component={ServiceDeskCreateIssue} />
                  <Route
                    path="/manageGallery"
                    render={(props) => (
                      <GalleryComponent {...props} isSuperAdmin={true} />
                    )}
                  />
                  <Route component={HomePage} />
                </Switch>
              </Container>
            </div>
          );
        }}
      />
    </div>
  );
};

const state = [{ title: "profile", path: "firebase.profile" }];

const App = (props: any) => (
  <FireStoreContainer state={state}>
    <AppInner {...(props as IAppInnerProps)} />
  </FireStoreContainer>
);

export default App;
