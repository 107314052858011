import firebase from "firebase/app";
import "firebase/functions";
import {
  IFindResult,
  ISearchRequest,
} from "../../../../functions/src/http/findandreplace/find";
import { ICreateIssueRequest } from "../../../../functions/src/http/servicedesk/createIssue.function"

export const createFunction = <T = any, R = any>(
  name: string
): ((data: T) => Promise<R>) => {
  return async (data: T) => {
    const callable = firebase.functions().httpsCallable(name);
    return (await callable(data)).data;
  };
};


const functionTypes = {
  nodeSearch: createFunction<ISearchRequest, IFindResult[]>("nodeSearch"),
  nodeSearchRefresh: createFunction<void,void>("refreshFindAndReplaceDatabase"),
  createIssue: createFunction<ICreateIssueRequest,void>("servicedesknewissue")
};

export default functionTypes;
