import { useState } from "react";
import { Button, Checkbox, Grid, Icon, Segment } from "semantic-ui-react";
import SearchResultItemDetail from "./SearchResultItemDetail";
import { extractUpdateObject, replaceInObject } from "./Shared";
import { NodePaneProps, HighLightType } from "./types";
import firebase from 'firebase/app'
import 'firebase/firestore'
import { connect } from "react-redux";


const SearchResultItem = ({
  searchTerm,
  replaceTerm,
  nodeData,
  onBulkApplyChange,
  bulkApplyChecked,
  fields,
  markAsReplaced,
  currentUser
}: NodePaneProps) => {
  const [detailOpen, setDetailOpen] = useState(false);

  const onReplace = async ()=> {
    const nodeUpdate = JSON.parse(JSON.stringify(nodeData.nodeContent));

    replaceInObject(
      nodeUpdate,
      searchTerm,
      replaceTerm,
      fields,
      HighLightType.None
    );
    
    const limitedUpdateNode = extractUpdateObject(nodeUpdate,replaceTerm,fields, currentUser || "")
console.log(limitedUpdateNode)
    const nodeReference = firebase.firestore().collection(nodeData.nodePath).doc(nodeData.nodeId);
    await nodeReference.update(limitedUpdateNode);
    markAsReplaced && markAsReplaced(nodeData.nodeId)

  }
 


  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <Icon color="blue" fitted size="huge" name="edit" />
          </Grid.Column>
          <Grid.Column width={9}>
            <p>
              <b>Node update</b> in {nodeData.nodePath} Pathway
            </p>
            <p>Node title: {nodeData.nodeContent.title}</p>
          </Grid.Column>
        
          <Grid.Column width={4}>
          { replaceTerm && 
            <Button
              style={{ marginBottom: "5px" }}
              onClick={ onReplace}
            >
              Replace
            </Button>
          }
            <Checkbox
              style={{ marginBottom: "5px" }}
              label="Include in group replace"
              value={nodeData.nodeId}
              onClick={(e, { value }) =>{} }
               checked={bulkApplyChecked.some(x=> x === nodeData.nodeId)} 
               onChange={(e, { value }) => onBulkApplyChange(value as string)}
            />
            <Button onClick={() => setDetailOpen(!detailOpen)}>
              {detailOpen ? "Hide Details" : "View Details"}
            </Button>
          </Grid.Column>
        </Grid.Row>
        {detailOpen && (
          <Grid.Row>
            <Grid.Column>
              <SearchResultItemDetail
                nodeData={nodeData}
                searchTerm={searchTerm}
                replaceTerm={replaceTerm}
                bulkApplyChecked={bulkApplyChecked}
                onBulkApplyChange={onBulkApplyChange}
                fields={fields}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

export default connect((state : any,ownProps: any) => { return { currentUser: state.firebase.profile.email, ...ownProps}}, null)(SearchResultItem);
