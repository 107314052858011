import React from "react";
import { connect } from "react-redux";
import { getDefaultTenantId } from "../../../app/common/access";
import SearchableDropdown, { ISimpleList } from "./SearchableDropdown";
import { useEffect } from "react";
import firebase from "../../config/firebase";
import { useState } from "react";

interface IPathwayDropDownProps {
  onPathwaySelect?: (data: string) => void;
  onPathwaySelectMultiple?: (data: string[]) => void;
  tenantId?: string;
  firebase?: any;
  placeholder?: string;
  defaultOption?: string;
  allowMultiple?: boolean;
}

const db = firebase.firestore();

const PathwayDropdown = ({
  onPathwaySelect,
  onPathwaySelectMultiple,
  tenantId,
  firebase,
  placeholder,
  defaultOption,
  allowMultiple,
}: IPathwayDropDownProps) => {
  if (!Boolean(tenantId)) {
    const defaultTenant = getDefaultTenantId(firebase.profile);
    if (!Boolean(defaultTenant)) {
      throw Error("User has no default tenant");
    }
    tenantId = defaultTenant as string;
  }

  if (!Boolean(placeholder)) {
    placeholder = "- Select a Pathway -";
  }

  if (allowMultiple && onPathwaySelectMultiple) {
  }

  useEffect(() => {
    async function Load() {
      const dropDownData: ISimpleList[] = [];
      const pathwayRefs = await db
        .collection("referenceData/pathwayData/pathways")
        .get();
      pathwayRefs.forEach((pathway) => {
        dropDownData.push({
          value: `${pathway.id}`,
          text: pathway.id,
        } as ISimpleList);
      });
      setPathwayDropDownSimpleList(dropDownData);
    }
    Load();
  }, []);

  const [pathwayDropDownSimpleList, setPathwayDropDownSimpleList] = useState<
    ISimpleList[]
  >([]);

  const [selectedPathways, setPathways] = React.useState<string>();
  const [selectedPathwaysMultiple, setSelectedPathwaysMultiple] =
    React.useState<string[]>([]);

  const onPathwaySelectInternal = (value: string) => {
    setPathways(value);
    onPathwaySelect && onPathwaySelect(value);
  };
  const onPathwaySelectMultipleInternal = (value: string[]) => {
    setSelectedPathwaysMultiple(value);
    onPathwaySelectMultiple && onPathwaySelectMultiple(value);
  };

  return (
    <>
      <label>Selected Pathway(s)</label>
      <SearchableDropdown
        placeholder="- All Pathways selected -"
        simpleList={pathwayDropDownSimpleList}
        onSelectMultiple={onPathwaySelectMultipleInternal}
        onSelection={onPathwaySelectInternal}
        value={allowMultiple ? selectedPathwaysMultiple : selectedPathways}
        allowMultiple={allowMultiple}
      />
    </>
  );
};

export default connect((state: any) => state)(PathwayDropdown);
