import { Component, FC } from "react";
import { withRouter } from "react-router-dom";
import { Button, Segment, StrictSegmentProps, Icon } from "semantic-ui-react";

// app
import brandConfig from "../../brandConfig.json";

// App
import childrenWithProps from "../react/ChildrenWithProps";

interface IPageContainerProps {
  title: any;
  children: React.ReactNode;
  history: any;
}

const PageContainer: FC<IPageContainerProps> = ({
  title,
  children,
  history,
  ...rest
}) => {
  return (
    <Segment>
      <Segment
        inverted
        color={brandConfig.main.primaryColor as StrictSegmentProps["color"]}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          icon
          style={{ marginRight: "1rem" }}
          onClick={() => history.goBack()}
        >
          <Icon name="arrow left" />
        </Button>
        <span style={{ fontSize: "x-large", fontWeight: "bolder" }}>
          {title}
        </span>
      </Segment>
      {childrenWithProps(children, rest)}
    </Segment>
  );
};

class PageContainerWrapper extends Component<any> {
  render() {
    return (
      <PageContainer
        title={this.props.title}
        history={this.props.history}
        {...this.props}
      >
        {this.props.children}{" "}
      </PageContainer>
    );
  }
}

export default withRouter(PageContainerWrapper);
