// Vendor
import { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";

// App
import brandConfig from "../../brandConfig.json";

const useServiceWorkerUpdate = () => {
  const history = useHistory();

  const handleUpdateServiceWorker = () => {
    navigator.serviceWorker.getRegistrations().then((regs) =>
      regs.forEach((reg) => {
        reg.waiting.postMessage({ type: "SKIP_WAITING" });
      })
    );
    window.localStorage.setItem("updateingSW", true);
    window.location.reload();
  };

  const handleAwaitingServiceWorker = (reg) => {
    const waitingWorker = reg.waiting;
    if (waitingWorker == null) {
      return;
    }
    if (navigator.serviceWorker.controller) {
      toastr.confirm(
        `A new version of ${brandConfig.main.title} is available; click OK to update. If any issues, restart your browser.`,
        {
          onOk: handleUpdateServiceWorker,
        }
      );
    }
  };

  useEffect(() => {
    history.listen((location, action) => {
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => {
        regs.forEach((reg) => {
          reg.onupdatefound = handleAwaitingServiceWorker;
          handleAwaitingServiceWorker(reg);
          reg.update();
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  });
};

export default useServiceWorkerUpdate;
