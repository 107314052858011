import { useState } from "react";
import { Button, Grid } from "semantic-ui-react";
import CombinedResultAsPreview from "./CombinedResultAsPreview";
import SideBySideResultAsPreview from "./SideBySideResultAsPreview";
import { NodePaneProps } from "./types";

const SearchResultItemDetail = ({
    searchTerm,
    replaceTerm,
    nodeData,
    onBulkApplyChange,
    bulkApplyChecked,
    fields,
  }: NodePaneProps) => {
    const [viewMode, setViewModel] = useState("combinedPreview");
  
    return (
      <>
        <Grid>
          <Grid.Row >
            <Grid.Column floated='left' width={5}>
              <Button.Group>
                <Button onClick={() => setViewModel("combinedPreview")}>
                  Combined
                </Button>
                {replaceTerm && <Button onClick={() => setViewModel("sideBySidePreview")}>
                  Side by side
                </Button>}
              </Button.Group>
              </Grid.Column>
  
             
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {viewMode === "combinedPreview" ? (
                <CombinedResultAsPreview
                  nodeData={nodeData}
                  searchTerm={searchTerm}
                  replaceTerm={replaceTerm}
                  bulkApplyChecked={bulkApplyChecked}
                  onBulkApplyChange={onBulkApplyChange}
                  fields={fields}
                />
              ) : (
                ""
              )}
              {viewMode === "sideBySidePreview" ? (
                <SideBySideResultAsPreview
                  nodeData={nodeData}
                  searchTerm={searchTerm}
                  replaceTerm={replaceTerm}
                  bulkApplyChecked={bulkApplyChecked}
                  onBulkApplyChange={onBulkApplyChange}
                  fields={fields}
                />
              ) : (
                ""
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  export default SearchResultItemDetail