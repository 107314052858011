import React from "react";
import { Grid } from "semantic-ui-react";
import PathwayRecentActivityNodePreview from "../PathwayRecentActivity/PathwayRecentActivityNodePreview";
import { replaceInObject } from "./Shared";
import { HighLightType, NodePaneProps } from "./types";


/* tslint:disable */
const JSPathwayRecentActivityNodePreview: any =
  PathwayRecentActivityNodePreview; //todo delete me after refactoring to TS
/* tslint:enable */

const SideBySideResultAsPreview = ({
    searchTerm,
    replaceTerm,
    nodeData,
    onBulkApplyChange,
    bulkApplyChecked,
    fields,
  }: NodePaneProps) => {
    //console.log(nodeData)
    const beforeNode = JSON.parse(JSON.stringify(nodeData.nodeContent));
    replaceInObject(
      beforeNode,
      searchTerm,
      replaceTerm,
      fields,
      HighLightType.Search
    );
  
    const afterNode = JSON.parse(JSON.stringify(nodeData.nodeContent));
    replaceInObject(
      afterNode,
      searchTerm,
      replaceTerm,
      fields,
      HighLightType.Replace
    );
  /*
    const [saving, setSaving] = React.useState(false);
  
    const onApply = async (property: string, nodeId: string, content: string) => {
      setSaving(true);
      const nodeReference = firebase
        .firestore()
        .collection(nodeData.nodePath)
        .doc(nodeData.nodeId);
      const obj = {} as any;
      obj[property] = content;
      await nodeReference.update(afterNode);
      setSaving(false);
    };
  */
    return (
      <Grid columns="equal">
        <Grid.Row columns={2}>
          <Grid.Column>
            <JSPathwayRecentActivityNodePreview
              updatedNode={true}
              tenantId={""}
              allowHtml={true}
              node={beforeNode}
              pathway={nodeData.nodePath}
              typeOfNodePreview={"Node before Update"}
            />
          </Grid.Column>
          <Grid.Column>
            <JSPathwayRecentActivityNodePreview
              updatedNode={true}
              tenantId={""}
              allowHtml={true}
              node={afterNode}
              pathway={nodeData.nodePath}
              typeOfNodePreview={"Node after Update"}
            />
          </Grid.Column>
        </Grid.Row>
        
        {/* Object.keys(nodeData).filter(x=> x !== "nodeId" && x !== "nodePath" && x !=='nodeContent' ).map((property : string) => {
                                  const item = highlightSearchTerm(searchTerm,nodeData[property], replaceTerm)
                                  return ( 
                                      
                                      <Grid.Row key={property}>
                                          <Grid.Column>
                                              {property}
                                          </Grid.Column>
                                          <Grid.Column>
                                              <div dangerouslySetInnerHTML={{__html: item.combined }}></div>
                                          </Grid.Column>
                                          <Grid.Column>
                                              {
                                                   replaceTerm && item.matchFound &&
                                                   <Checkbox value={nodeData.nodeId} checked={bulkApplyChecked.some(x=> x.id === nodeData.nodeId && x.property === property)} onChange={(e, { value }) => onBulkApplyChange(value as string, property, item.rawReplace || "")} />
                                              }
                                          </Grid.Column>   
                                          <Grid.Column>  
                                              {
                                              replaceTerm && item.matchFound &&
                                              <div style={{ display:"inline-block" }}><Button loading={saving} onClick={()=> onApply(property, nodeData.nodeId, item.rawReplace || "" )}>Apply</Button></div>
                                              }
                                          </Grid.Column>
                                      </Grid.Row>
                                      )
                                  }
                              )
                              */}
      </Grid>
    );
  };

  export default SideBySideResultAsPreview