//Copyright 2018 Nathan Moore
import { SubmissionError, reset } from "redux-form";
import { toastr } from "react-redux-toastr";
import firebase from "firebase";
import {
  SIGN_IN_ERROR,
  MFA_REQUIRED,
  SIGN_OUT_USER,
  SIGN_IN_USER,
  SIGN_IN_STATE_IDLE,
  MFA_UPDATE,
} from "./authConstants";

export const Login = ({ username, password }) => {
  return async (dispatch, getState, { getFirebase }, history) => {
    //const firebase = //getFirebase();
    try {
      const {
        auth: { MFAUpdate },
      } = getState();
      const auth = await firebase.auth();
      await auth.signInWithEmailAndPassword(username, password);
      dispatch(SignInUser({ MFAUpdate: MFAUpdate }));
    } catch (ex) {
      let errorMessage = ex.message;

      if (ex.code === "auth/multi-factor-auth-required") {
        const resolver = ex.resolver;

        const MFAHints = resolver.hints[0];
        if (
          resolver.hints[0].factorId ===
          firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
        ) {
          var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            },
            window.recaptchaVerifier
          );
          dispatch(
            MFARequired(
              MFAHints.displayName,
              MFAHints.phoneNumber,
              verificationId,
              resolver
            )
          );
          return;
        }
        errorMessage = "Unsupported MFA Type";
      }

      dispatch(SignInError(errorMessage));
    }
  };
};

export const MFAConfirm = (verificationCode) => {
  return async (dispatch, getState, { getFirebase }, history) => {
    try {
      //const verificationId = "";
      const {
        auth: { verificationId, resolver, MFAUpdate },
      } = getState();

      console.log("MFA Confirm Called");

      const phoneAuthCredential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        phoneAuthCredential
      );

      console.log("MFA Confirm Var", verificationId, resolver, MFAUpdate);
      //If we have been sent a resolver that it is an authentication request
      if (resolver != null) {
        console.log("2FA SignIn");
        await resolver.resolveSignIn(multiFactorAssertion);
      }
      //If the user does have an enrolled factor the it is an unenroll request
      if (
        MFAUpdate &&
        !!firebase.auth().currentUser.multiFactor.enrolledFactors.length
      ) {
        console.log("2FA Unenroll");
        var phoneFactor = firebase
          .auth()
          .currentUser.multiFactor.enrolledFactors.find(
            (factor) =>
              factor.factorId ===
              firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
          );
        await firebase.auth().currentUser.multiFactor.unenroll(phoneFactor);
      }

      //If the current does not have any enrolled factors it is an enroll request
      if (
        resolver == null &&
        !firebase.auth().currentUser.multiFactor.enrolledFactors.length
      ) {
        console.log("2FA Enroll");
        await firebase
          .auth()
          .currentUser.multiFactor.enroll(multiFactorAssertion);
      }

      dispatch(SignInUser());
    } catch (ex) {
      console.log(ex);
      dispatch(SignInError(ex.message));
    }
  };
};

/*
export const MFAUnEnroll = (verificationCode) => {
  return async (dispatch, getState, { getFirebase }, history) => {
    try {

      var phoneFactor = firebase.auth().currentUser.multiFactor.enrolledFactors.find(factor => factor.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID)
      console.log("Factors",firebase.auth().currentUser.multiFactor.enrolledFactors)
      console.log("FactorId", firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID)
      console.log("Found Factory",phoneFactor)
      await firebase.auth().currentUser.multiFactor.unenroll(phoneFactor);
      //dispatch({ type: SIGN_IN_USER, payload: user });
    } catch (ex) {
      dispatch(SignInError(ex.message));
    }
  };
};
*/
export const MFARegister = (phoneNumber) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      const multiFactorSession = await firebase
        .auth()
        .currentUser.multiFactor.getSession();
      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber: phoneNumber, session: multiFactorSession },
        window.recaptchaVerifier
      );
      dispatch(MFARequired("Phone Number", phoneNumber, verificationId, null));
    } catch (ex) {
      dispatch(SignInError(ex.message));
    }
  };
};

export const SignOut = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.logout();
    dispatch({
      type: SIGN_OUT_USER,
    });
  };
};

export const SignInError = (message) => {
  return { type: SIGN_IN_ERROR, payload: { error: message } };
};

export const SignInUser = (payload) => {
  return { type: SIGN_IN_USER, payload: payload };
};

export const SetLoginIdle = () => {
  return { type: SIGN_IN_STATE_IDLE };
};

export const MFAUpdate = () => {
  return {
    type: MFA_UPDATE,
  };
};

export const MFARequired = (
  displayName,
  phoneNumber,
  verificationId,
  resolver
) => {
  return {
    type: MFA_REQUIRED,
    payload: {
      MFAType: displayName,
      MFAHint: phoneNumber,
      verificationId: verificationId,
      resolver,
    },
  };
};

export const updatePassword = (creds) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  try {
    await user.updatePassword(creds.newPassword1);
    await dispatch(reset("account"));
    toastr.success("Success", "Your password has been updated");
  } catch (error) {
    throw new SubmissionError({
      _error: error.message,
    });
  }
};
