import { useState, useEffect } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import React from "react";

/**
 * Searchable Dropdown component
 */

export interface IDropdownData {
  name: string;
  items: IDropdownDataItems[];
}

interface IDropdownDataItems {
  name: string;
  displayName: string;
  key?: string;
}

export interface ISimpleList {
  value: string;
  text: string;
}

interface ISearchableDropdownProps extends DropdownProps {
  dropdownData?: IDropdownData[];
  placeholder?: string;
  onSelection?: (value: string) => void;
  onSelectMultiple?:  (value: string[]) => void;
  value?: string | string[];
  simpleList?: ISimpleList[];
  startingOption?: string;
  allowMultiple?: boolean
}

const SearchableDropdown = ({
  dropdownData, // Standard Data (array of objects with children)
  placeholder, // placeholder content for the dropdown field (previously a disabled select option)
  onSelection, // The function we should fire when the selection has changed
  onSelectMultiple,
  value, // If there is currently a value that has been set,
  simpleList, // Simple array of strings used as options
  startingOption, // If there is an option that should be added at the top of the list
  allowMultiple,
  ...props
} : ISearchableDropdownProps) => {

  const [currentList, setCurrentList] = useState<any[]>([]);

  const onChange = (event: React.SyntheticEvent, data: DropdownProps): void => {
    if(allowMultiple){
      onSelectMultiple && onSelectMultiple(data.value as string[])
    }else{
      onSelection && onSelection(data.value as string);
    }
    
  };

  const generateList = (): void => {
    let fullList: any = [];

    if (dropdownData && dropdownData.length > 0) {
      dropdownData.forEach((item: IDropdownData) => {
        fullList.push(
          <Dropdown.Header content={item.name} key={`${item.name}-header`} />,
          <Dropdown.Divider key={`${item.name}-divider`} />
        );

        item.items.forEach((subitem: IDropdownDataItems) => {
          fullList.push({
            value: subitem.name,
            text: subitem.displayName,
            key: subitem.name,
          });
        });

        setCurrentList(fullList);
      });
    }

    if (simpleList && simpleList.length > 0) {
      let fullListWithKeys = [
        ...simpleList.map((item: ISimpleList) => ({
          value: item.value,
          text: item.text,
          key: item.value,
        })),
      ];

      if (startingOption) {
        fullList = [
          {
            value: startingOption,
            text: startingOption,
            key: startingOption,
          },
          ...fullListWithKeys,
        ];
      } else {
        fullList = [...fullListWithKeys];
      }

      setCurrentList(fullList);
    }
  };

  if (!currentList.length) generateList();

  const multiple =  allowMultiple || false

  
  useEffect(() => {
    setCurrentList([]);
  }, [dropdownData, simpleList]);

  return (
    <Dropdown
      placeholder={placeholder}
      search
      fluid
      selection
      options={currentList}
      onChange={onChange}
      value={value as string}
      {...props}
      multiple={multiple}
    />
  );
};

export default SearchableDropdown;
